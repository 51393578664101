import { get } from "lodash";

const createField = keys => {
    let updateLocation = keys.join("/").replace(/\(.*\)/g, "").replace(/\s/g, "");
    const key = updateLocation.replace("/", ".");
    return {
        "label": keys.join(" ").split(/(?=[A-Z])/).join(" "),
        "key": `Security.${key}`,
        "type": "bool",
        "actionButton": true,
        "actionText": "Toggle",
        "actionClass": "primary",
        action({ pageItem, db }) {
            // updateLocation is the location of the item in the database, we need to join with the keys to get the correct location with / seperators. then we need to remove anythin within () as this is used for labels
            
            db.set(`Userdata/${pageItem.ID}/Security/${updateLocation}`, !get(pageItem, `Security.${key}`, false));
        }
    };
};


const secMap = {
    "General": [
        "User",
        "Admin",
        "UserAdmin",
        "Training",
        "ConfidentialReportsUser"
    ],
    "Database": [
        "Read",
        "Write",
        "Admin",
        "DrawingApprover",
        "DrawingChecker",
        "TechnicalSecretary",
        "Projects (Commercials)"
    ],
    "Audits":[ 
        "Read",
        "Write",
        "Admin",
        "DAM",
        "HOD"
    ],
    "Claims":[ 
        "User",
        "Authoriser",
        "Payer"
    ],
    "FlightTest":[ 
        "Read",
        "Write",
    ],
    "Leave":[ 
        "Read",
        "Write",
        "Admin",
        "Authoriser"
    ],
    "Matrices":[ 
        "Read",
        "Write",
        "Reviewer",
        "HeadOfDesign"
    ]
};

export default Object.entries(secMap).map(([key, value]) => {
    return {
        "label": key.split(/(?=[A-Z])/).join(" "),
        "key": key,
        "local": true,
        "field": true,
        "fields": value.map(i => {
            return createField([key, i]);
        })
    }
});
