const isEADT = ({ liveInputData }) => {
    return ["EADT"].includes(liveInputData.Type);
};

export const eadtFormFields = [{
    "label": "Item Name",
    "name": "Name",
    "type": "text",
    "show": isEADT
}, {
    "label": "Part Number",
    "name": "PartNumber",
    "type": "text",
    "show": isEADT
}, {
    "label": "Description",
    "name": "Description",
    "divClass": "md:col-span-3",
    "type": "richtext",
    show({ liveInputData }) {
        return ["EAF", "EADT"].includes(liveInputData.Type);
    }
}, {
    "label": "Qualification",
    "name": "Qualification",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Limitations",
    "name": "Limitations",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Additional Data",
    "name": "AdditionalData",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Required Maintenance",
    "name": "RequiredMaintenance",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Suitability",
    "name": "Suitability",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Documentation",
    "name": "Documentation",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Test / Setup Requirements",
    "name": "TestRequirements",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "show": isEADT
}, {
    "label": "Datasheet Upload",
    "type": "file",
    "name": "Datasheet",
    "divClass": "md:col-span-3",
    "show": isEADT,
    "required": false
}];

export const eadtFields = [{
    "label": "Manufacturer",
    "key": "Manufacturer",
    "grow": true,
}, {
    "label": "Item Name",
    "key": "Name",
    "grow": true
}, {
    "label": "Part Number",
    "key": "PartNumber",
    "grow": true
}, {
    "label": "Description",
    "key": "Description",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Qualification",
    "key": "Qualification",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Limitations",
    "key": "Limitations",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Additional Data",
    "key": "AdditionalData",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Required Maintenance",
    "key": "RequiredMaintenance",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Suitability",
    "key": "Suitability",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Documentation",
    "key": "Documentation",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Test / Setup Requirements",
    "key": "TestRequirements",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Datasheet",
    "key": "Datasheet",
    "class": "md:col-span-3",
    "actionButton": true,
    "actionText": "Download",
    "action": async ({item, db}) => {
        let url = await db.download(`Database/Documents/${item.ID}/Datasheet/${ item.Datasheet}`); 
        window.open(url);  
    }
}];

export function editEadtData({ item, db, form }) {
    form.setValues({...item, 
        "Datasheet": null
    });
    form.setConfig({
        "title": "Revise EADT",
        "location": `Database/Documents/${item.ID}/`,
        "type": "update",
        "fields": eadtFormFields,
        async preSubmitHook({ files }) {
            if(files.length > 0) return {};
            for (const file of files) {
                await db.upload(`Database/Documents/${item.ID}/Datasheet`, file[0]);
                await db.set(`Database/Documents/${item.ID}/Datasheet`, file[0].name);
            }
        },
        async postSubmitHook() {
            form.toggle();
        }
    });
    form.toggle();
}

export const completeEadt = ({ item, db, form }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Complete EADT",
        "location": `Database/Documents/${item.ID}/`,
        "description": "Are you sure you want to complete this EADT?",
        "type": "update",
        "fields": [],
        async preSubmitHook({ files }) {
            return {
                "Completed": true,
            }
        }
    });
    form.toggle();
};


