import {
    downloadPdf
} from "../../../pdfDownloader.js";
import htmlToPdfmake from "html-to-pdfmake";
import {
    standardDateLocal
} from "../../../utils.js";

const keys = { 
    NonConformities(items, pageItem) {
        let body = [
            ["Number", "Procedure", "Cycle", "Completed"]
        ];
        items.forEach(item => {
            body.push([item.Number, item.Procedure, item.Cycle, item.Completed ? "Yes" : "No"])
        });
    }
}

export const reportItemsReport = ({ pageItem, attachedItems, store }) => {
    let docDef = {
        "content": []
    };
    let con = docDef.content;

    // Design Chnage Report Title
    con.push({
        "text": `S21.${pageItem.Number} - ${pageItem.Title}`,
        "fontSize": 12
    });

    // Add some basic infomation about the Design Change
    con.push({
        "columns": [{
            "width": "33%",
            "text": "Number",
            "bold": true
        }, {
            "width": "33%",
            "text": "Title",
            "bold": true
        }, {
            "width": "33%",
            "text": "Procedure",
            "bold": true
        }],
        "margin": [0, 12]
    });

    con.push({
        "columns": [{
            "width": "33%",
            "text": pageItem.Number
        }, {
            "width": "33%",
            "text": pageItem.Title
        }, {
            "width": "33%",
            "text": pageItem.Procedure
        }]
    });

    con.push({
        "columns": [{
            "width": "33%",
            "text": "DAM",
            "bold": true
        }, {
            "width": "33%",
            "text": "HoD",
            "bold": true
        }, {
            "width": "33%",
            "text": "Date Created > Date Completed",
            "bold": true
        }],
        "margin": [0, 12]
    });

    let humanCreated = new Date(pageItem.Date.Created).toLocaleDateString("en-GB", standardDateLocal);
    let humanCompleted = new Date(pageItem.Date.Completed).toLocaleDateString("en-GB", standardDateLocal);
    let dates = `${humanCreated} > ${humanCompleted}`;

    con.push({
        "columns": [{
            "width": "33%",
            "text": pageItem.Number
        }, {
            "width": "33%",
            "text": pageItem.Title
        }, {
            "width": "33%",
            "text": dates
        }]
    });
    
    htmlToPdfmake(pageItem.Comment, { 
        ignoreStyles: ['font-family'] 
    }).forEach(i => con.push(i)); 

    // Add all the tables
    for (let [key, value] of Object.entries(attachedItems)) {
        if (value.items.length < 1) continue;
        if (!keys[key]) continue;

        let items = value.items.map(i => store.state[i]).filter(i => !!i).filter(i => !i.Cancelled);

        let body = keys[key](items, pageItem);
        con.push({
            "text": key,
            "fontSize": 12,
            "margin": [0, 12, 0, 12]
        });

        con.push({
            "table": {
                // headers are automatically repeated if the table spans over multiple pages
                // you can declare how many rows should be treated as headers
                "headerRows": 1,
                "body": body,
            }
        })
    };
    downloadPdf(docDef)
};
