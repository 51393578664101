import {
    addFile,
    editPurchaseOrder,
    removeFile
} from "./actions";
import { averageQuotePricePerYear, completedDesignChangesByMonth, completedJobCardsByMonth, purchaseOrderValue, purchaseOrdersByMonthAndYear, purchaseOrdersNumbersByCustomer, totalPerYear, uptake, uptakeAllTime } from "./PerformanceMetrics/chartConfigs";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import standardMenuActions from "../../standardMenuActions";

const PerformanceMetrics = () => import("./PerformanceMetrics/PerformanceMetrics.vue");

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Commercial",
    "link": "Database/Commercials/:CommercialID"
}, {
    "label": "Customer",
    "key": "Customer",
}, {
    "label": "Price",
    "key": "Price"
    }, {
        "label": "Percentage Invoiced",
        "key": "PercentageInvoiced"
    }, {
    "label": "Percentage Paid",
    "key": "PercentagePaid"
}];

const menuActions = [
    {
        "label": "Edit Purchase Order",
        "action": editPurchaseOrder
    },
    standardMenuActions.back
];

const files = {
    "label": "Supporting Files",
    "key": "Files",
    "local": true,
    "actions": [{
        "label": "Add File",
        "action": addFile
    }],
    "headers": [{
        "headerName": "File Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Open File",
        async action({ db, rowItem, pageItem }) {
            let url = await db.download(`Database/PurchaseOrders/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.Name}`);
            window.open(url, "__blank");
        }
    }, {
        "label": "Remove File",
        "action": removeFile
    }]
};


export default [{
    "path": "/Database/PurchaseOrders",
    "component": { ...ItemListView },
    "name": "Purchase Orders",
    "meta": {
        "app": "Database",
        "name": "Purchase Orders",
        "sideNav": true,
        "sideNavSection": "Projects",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "PurchaseOrders",
        "dataPath": "Database/PurchaseOrders",
        "selectionRoute": "Database/PurchaseOrders/{ID}",
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Database/PurchaseOrderMetrics",
    "component": PerformanceMetrics,
    "name": "Performance Metrics",
    "meta": {
        "app": "Database",
        "name": "Purchase Order Performance Metrics",
        "sideNav": true,
        "sideNavSection": "Projects",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "PurchaseOrders",
        "dataPath": "Database/PurchaseOrders",
        "selectionRoute": "Database/PurchaseOrders/{ID}",
        "options": {
            purchaseOrderValue,
            purchaseOrdersNumbersByCustomer,
            averageQuotePricePerYear,
            totalPerYear,
            uptake,
            uptakeAllTime,
            purchaseOrdersByMonthAndYear,
            completedDesignChangesByMonth,
            completedJobCardsByMonth
        },
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        }


    }
}, {
    "path": "/Database/PurchaseOrders/:id",
    "component": ItemView,
    "name": "Purchase Order",
    "meta": {
        "app": "Database",
        "name": "Purchase Orders",
        "dataPath": "Database/PurchaseOrders",
        "selectionRoute": "Database/PurchaseOrders/{ID}",
        title(item) {
            return `Purchase Order: ${item.Number}`
        },
        "attachedItems": [],
        "dataFields": dataFields,
        "menuActions": menuActions,
        lock(user) {
            return [user?.Security?.Database?.Projects !== true, "Cannot view this area"]
        },
        "attachedItems": {
            "Files": {
                "attachables": [files]
            }
        }   
    }
}];
