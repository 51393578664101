import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Parent",
    "link": "Database/Commercials/:ParentID"
}, {
    "label": "Responsible Engineer",
    "key": "ResponsibleEngineer"
}, {
    "label": "Customer",
    "key": "Customer"
}, {
    "label": "STC Approval Number",
    "key": "EASANumber"
}, {
    "label": "Mod Type",
    "key": "Type"
}, {
    "label": "Date Created",
    compute({ item }) {

        let d = new Date(item.Date.Created);
        return d.toLocaleDateString("en-GB", {
            "year": 'numeric', "month": 'long', "day": 'numeric'
        })
    }
}, {
    "label": "Date Completed",
    compute({ item }) {
        let d = new Date(item.Date.Completed);
        return d.toLocaleDateString("en-GB", {
            "year": 'numeric', "month": 'long', "day": 'numeric'
        })
    }
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];


export default [{
    "path": "/Database/DesignChanges",
    "component": { ...ItemListView },
    "name": "Design Changes",
    "meta": {
        "app": "Database",
        "name": "Design Changes",
        "sideNavSection": "Designs",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 1,
        "security": "Database.Read",
        "icon": "database",
        "tableSettings": "DesignChanges",
        "dataPath": "Database/Refs/DesignChanges",
        "selectionRoute": "Database/DesignChanges/{ID}"
    }
}, {
    "path": "/Database/DesignChanges/:id",
    "component": ItemView,
    "name": "Design Change",
    "meta": {
        "app": "Database",
        "name": "Design Changes",
        "dataPath": "Database/DesignChanges",
        "selectionRoute": "Database/DesignChanges/{ID}",
        title(item) {
            return `Design Changes: ${item.Number}`
        },
        async lazyLoadMeta() {
            const { designChangeItemsReport } = await import("./reports");
            const { editDesignChange, completeDesignChange, cancelDesignChange } = await import("./actions");
            const { createBoxRequest } = await import("../BoxRequests/actions.js");
            const { default: documentsAttachable } = await import("../Documents/attachable.js");
            const { default: drawingAttachable } = await import("../Drawings/attachable.js");
            const { default: saddAttachable } = await import("../SADDs/attachable.js");
            const { default: effectivitiesAttachable } = await import("../Effectivities/attachable.js");
            const { default: concessionAttachable } = await import("../Concessions/attachable.js");
            const { default: dqnsAttachable } = await import("../DQNs/attachable.js");
            const { default: doiAttachable } = await import("../DOIs/attachable.js");
            const { default: sbAttachable } = await import("../ServiceBulletins/attachable.js");
            const { default: arrAttachable } = await import("../Arrangements/attachable.js");
            const { stcs: stcAttachable, stcVals: stcValsAttachable } = await import("../STCs/attachable.js");
            const { default: sowAttachable } = await import("../SOWs/attachable.js");
            const { default: commercialsAttachable } = await import("../Commercials/attachable.js");
            const { default: jobCardsAttachable } = await import("../JobCards/attachable.js");
            const { thirdPartyDocs, thirdPartyDraws, thirdPartyOther } = await import("../Miscellaneous/attachable.js");

            return {
                "menuActions": [
                    {
                        "label": "Download Design Change Report",
                        "action": designChangeItemsReport
                    },
                    {
                        "label": "Edit Design Change",
                        "action": editDesignChange,
                        "break": true
                    },
                    {
                        "label": "Complete Design Change",
                        "action": completeDesignChange,
                        show({ attachedItems, pageItem }) {
                            if (pageItem.Completed) return [false, 'The Design Change has already been completed'];
                            if (!pageItem.Effectivities) return [false, 'This Design Change does not have any Effectivities'];
                            let warnings = Object
                                .values(attachedItems)
                                .filter(ai => ai.warn[0])
                                .map(ai => `${ai.label}<br>${ai.warn[1]}`)
                                .join("<br>");

                            if (warnings.length > 0) return [false, warnings];
                            else return [true, ''];
                        }
                    },
                    {
                        "label": "Cancel Design Change",
                        "class": "text-red hover:bg-red",
                        "color": "red",   
                        "action": cancelDesignChange,
                        "break": true
                    },
                    {
                        "label": "Create Box Request",
                        "action": createBoxRequest,
                        "break": true
                    },
                    {
                        "label": "View in Archive",
                        "break": true,
                        action({ pageItem }) {
                            window.open(`https://stc21.sharepoint.com/sites/archive/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Farchive%2FShared%20Documents%2FDesign Changes/${pageItem.ATAChapter}/S21_${pageItem.Number} ${pageItem.Title}`)
                        }
                    }
                ],
                "attachedItems": {
                    "Design Items": {
                        "attachables": [documentsAttachable, drawingAttachable, saddAttachable, effectivitiesAttachable]
                    },
                    "Design Queries": {
                        "attachables": [concessionAttachable, dqnsAttachable, sbAttachable, doiAttachable]
                    },
                    "Project Items": {
                        "attachables": [
                            commercialsAttachable,
                            jobCardsAttachable,
                            sowAttachable,
                            stcAttachable,
                            stcValsAttachable,
                            arrAttachable,
                            thirdPartyDocs,
                            thirdPartyDraws,
                            thirdPartyOther
                        ]
                    }
                }
            }
        },
        "dataFields": dataFields,
        "formMetadata": {
            "ParentType": "DesignChanges"
        }
    }
}];
