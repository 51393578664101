export const addPartNumber = ({ form, item }) => {
    form.setConfig({
        "title": "Add Part Number",
        "type": "push",
        "location": "Database/PartNumbers",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }],
        preSubmitHook() {
            return {
                
                "Manufacturer": item.Manufacturer,
                "DocumentID": item.ID,
                "Document": item.Number
            }
        }
    });
    form.toggle();
}  

export const editPartNumber = ({ form, item }) => {
    form.setValues({
        "Number": item.Number
    })
    form.setConfig({
        "title": "Add Part Number",
        "type": "update",
        "location": `Database/PartNumbers/${item.ID}`,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }]
    });
    form.toggle();
}  
