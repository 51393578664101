<template>
  <div class="w-full flex flex-col" v-if="!!tableSettings">
    <div class="m-2 p-2 bg-white rounded shadow flex flex-col" style="max-height: 100%; height: 100%">
      <div class="flex flex-row">
        <p class="p-2 text-lg font-medium text-primary flex-grow">
          {{ hideCancelled ? "" : "Cancelled " }} {{ currentRoute.name }}
        </p>
        <IconMenu :actions="[
    ...(routeMeta?.menuActions || []),
    download,
    ...state.columnOnOffSwitchs,
    {
      break: true,
      label: hideCancelled ? 'Show Cancelled' : 'Hide Cancelled',
      action() {
        hideCancelled = !hideCancelled;
      },
    },
  ]" :contextData="contextData" />
      </div>
      <Loading message="Loading Items" v-if="state.loading" />
      <Input ref="quickSearch" label="Quick Search" v-model="quickFilter" />
      <DataTable ref="table" :items="items" @itemSelected="itemSelected" @optionsChanged="checkTableSettings"
        :paginationAutoPageSize="true" :quickFilter="quickFilter" :tableSettings="tableSettings" />
    </div>
  </div>
  <DynamicForm ref="form" @submitted="$forceUpdate" />
</template>
<script setup>
import {
  computed,
  onMounted,
  watch,
  ref,
  reactive,
  onBeforeMount,
  getCurrentInstance
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { db } from "../../db.js";
import { get, set, pickBy, identity } from "lodash";
import DynamicForm from "../DynamicForm/DynamicForm.vue";
import {
  DataTable,
  IconMenu,
  Loading,
  Input,
} from "@comp/index.js";

import { useToast } from "vue-toastification";

const instance = getCurrentInstance();

// inistalise vue objects
const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

// set the component refs
let table = ref(null);
let form = ref(null);

let routeMeta = ref(null);

// loading
const loading = ref(true);

// make them accessible in the template
const currentRoute = computed(() => route);

const quickFilter = ref("");

const hideCancelled = ref(true);

let state = reactive({
  loading: true,
  columnOnOffSwitchs: computed(() => {
    if (columnState.value) {
      return columnState.value.map((columnState, index) => {
        let color = columnState.hide ? "red" : "green";

        return {
          label: `<div class='w-3 h-3 p-0 m-2 rounded-full bg-${color}'></div><p class="text-${color}">${columnState.headerName}</p>`,
          break: index === 0,
          action() {
            table.value.columnApi.setColumnVisible(
              columnState.colId,
              columnState.hide
            );
          },
        };
      });
    }
    return [];
  }),
});

const tableSettings = computed(() => {

  if (!store.state.user.Settings.tables[routeMeta?.value?.tableSettings]) {
    return null;
  }
  const _tableSettings =
  {
    ...store.state.user.Settings.tables[routeMeta?.value?.tableSettings],
    ...(routeMeta?.value?.tableSettingsOverride || {})
  };
  (routeMeta.value?.additionalTableHeaders || []).forEach((ath) => {
    let index = _tableSettings.columnState.findIndex((cs) => {
      return cs.headerName === ath.headerName;
    });
    if (index > -1) {
      _tableSettings.columnState[index] = {
        ..._tableSettings.columnState[index],
        ...ath,
      };
    } else {
      _tableSettings.columnState.push(ath);
    }
  });

  if (routeMeta.value?.alterTableSettings) {
    routeMeta.value?.alterTableSettings(_tableSettings)
  }

  if (_tableSettings?.filter) {
    _tableSettings.filter = Object.fromEntries(
      Object.entries(_tableSettings.filter).map(([key, value]) => {
        return [key.replace("_", "."), value];
      })
    );
  }
  return _tableSettings;
});

const columnState = computed(() => {
  const additionalTableHeaders = routeMeta.value?.additionalTableHeaders || [];
  let colState = tableSettings.value?.columnState || [];
  return [...additionalTableHeaders, ...colState];
});

const _items = computed(() => store.state[routeMeta?.value?.dataPath]);

// Get and compute all the items in the  view
// This is a computed property that returns an array of items.
const items = computed(() => {

  // Set the loading state to true to indicate data is still being fetched.
  state.loading = true;

  // Check if _items.value is null. If it is, return an empty array.
  if (!_items.value) {
    return [];
  } else {
    // If _items.value has data, filter and map it according to certain conditions.

    // Filter _items.value by DesignChangeFilter and routeMeta's filter function.
    let __items = Object.values(_items.value)
      .filter((i) => {
        if (store.state.user && store.state.user.DesignChangeFilter) {
          let idList = Object.keys(store.state.user.DesignChangeFilter);
          return idList.includes(i.ID) || idList.includes(i.ParentID);
        } else return true;
      })
      .filter(routeMeta?.value?.filter || (() => true))
      .map(routeMeta?.value?.map || ((i) => i))
      .filter((i) => {
        return !i.Cancelled === hideCancelled.value;
      })
      .map((item) => {

        // Create a copy of the item and add the ID key for consistency.
        let computedItem = {
          ...item,
          ID: item.ID,
        };

        // Depending on tableSettings' columnState or columns values, convert any Date field values to Date objects.
        if (tableSettings.value.columnState) {
          tableSettings.value.columnState.forEach((column) => {
            if (column.field.includes("Date.")) {
              function isDate(_date) {
                return (
                  _date &&
                  Object.prototype.toString.call(_date) === "[object Date]" &&
                  !isNaN(_date)
                );
              }
              let date = new Date(get(item, column.field));
              if (!isDate(date)) {
                set(computedItem, column.key, date);
              } else {
                set(computedItem, column.key, date);
              }
            }
          });
        } else if (tableSettings.value.columns) {
          tableSettings.value.columns.forEach((column) => {
            if (column.key.includes("Date.")) {
              set(computedItem, column.key, new Date(get(item, column.key)));
            }
          });
        }

        // Return the item with updated Date values.
        return computedItem;
      });

    // Set the loading state back to false after data has been retrieved.
    state.loading = false;

    // Return the filtered and mapped items.
    return __items;
  }
});

const itemSelected = (row) => {
  //if a command has been set run that command otherwise
  if (routeMeta?.value?.itemSelected) {
    routeMeta?.value?.itemSelected({
      ...contextData.value,
      item: row.data,
      instance: instance
    });
    return;
  }
  // then auto try to route to it
  let regex = RegExp("{(.*?)}");
  let match = routeMeta?.value?.selectionRoute.match(regex);
  router.push(
    `/${routeMeta?.value?.selectionRoute.replace(match[0], row.data[match[1]])}`
  );
};

let contextData = computed(() => {
  return {
    currentRoute: currentRoute.value,
    form: form.value,
    items: items.value,
    store,
    router,
    db,
    toast,
    instance: instance,
    meta: routeMeta.value?.formMetadata,
  };
});

const download = computed(() => {
  return {
    label: "Download CSV",
    action: table.value?.download,
    break: true,
  };
});

const checkTableSettings = async () => {
  try {
    const columnApi = table.value.columnApi;
    const gridApi = table?.value?.tableApi?.filterManager?.gridOptionsService.api;
    if (columnApi && gridApi) {
      const columns = columnApi.getColumns();
      const columnStates = columnApi.getColumnState();
      const columnDefs = gridApi.getColumnDefs();
      const _filter = gridApi.getFilterModel();

      const columnState = columnStates.map((columnState, index) => {
        const column = columns.find((columnDef) => {
          return columnDef.colDef.colId === columnState.colId;
        });

        return {
          ...columnState,
          type: columnDefs[index]?.type || "string",
          headerName: column?.colDef?.headerName,
          field: columnState.colId,
          // filterParams: { apply: true, newRowsAction: 'keep' }
        };
      });
      let tableSettings = pickBy(
        {
          columnState: columnState,
          filter: Object.fromEntries(
            Object.entries(_filter).map(([key, value]) => {
              return [key.replace(".", "_"), value];
            })
          ),
        },
        identity
      );


      await db.update(
        `Userdata/${store.getters.userID}/Settings/tables/${routeMeta?.value?.tableSettings}`,
        tableSettings
      );
    }
  } catch (e) {
    console.error(e);
  }
};

// TODO: Add a check to see if dataQuery is a function and call it if it is
function getListData(_meta) {
  if (typeof _meta?.value?.dataQuery === 'function') {
    _meta?.value?.dataQuery(store);
  } else if (_meta?.value?.dataQuery && _meta?.value?.dataQuery.key && _meta?.value?.dataQuery.value) {

    db.query(
      _meta?.value?.dataPath,
      _meta?.value?.dataQuery.key,
      _meta?.value?.dataQuery.value,
      store
    );
  } else {
    if (!_meta?.value?.dataPath) {
      return;
    }
    db.watch(_meta?.value?.dataPath, store);
  }
}

onMounted(() => {
  getListData(routeMeta);
});

onBeforeMount(async () => {
  if (currentRoute.value.meta.onBeforeMount) {
    await currentRoute.value.meta.onBeforeMount();
  }

  let meta = !!currentRoute.value.meta.lazyLoadMeta
    ? await currentRoute.value.meta.lazyLoadMeta()
    : {};

  routeMeta.value = {
    ...currentRoute.value.meta,
    ...meta,
  };

  loading.value = false;
});

watch(currentRoute.value, (_route) => {
  loading.value = true;
  getListData(routeMeta);
});
</script>
