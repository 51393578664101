<template>
  <div class="w-full flex flex-col">
    <div class="m-2 p-2 bg-white rounded shadow flex flex-col" v-if="loading">
      <Loading />
    </div>
    <div class="
        absolute
        top-2
        left-1/2
        transform
        translate-x-1/2
        bg-gray-50
        rounded
        p-2
        shadow
        flex flex-row
        items-center
      " v-if="!!currentRoute?.query?.linkedFrom">
      <router-link class="p-2 text- text-sm font-medium flex
        cursor-pointer hover:underline" :to="currentRoute?.query?.linkedFrom">Go back to {{
      currentRoute.query.linkedFromNumber }}</router-link>
      <font-awesome-icon class="text-cancel ml-4 cursor-pointer" icon="times"
        @click="router.replace({ 'query': null })"></font-awesome-icon>
    </div>
    <div class="m-2 p-2 bg-white rounded shadow flex flex-col" v-if="!!itemOverride">
      <Fields :title="route.meta.title(itemOverride || {}, contextData)"
        :contextData="{ ...contextData, item: itemOverride }" :dataFields="dataFields" :menuActions="menuActions">
      </Fields>
    </div>
    <div v-if="loaded">
      <div class="m-2 bg-white rounded shadow flex flex-col" v-show="!loading">
        <AttachableTables ref="tables" :tables="aiTree" :pageItem="itemOverride || item" :contextData="contextData"
          :selectedTableIndex="selectedTableIndex" @selectedtableindexchanged="selectedTableIndexChanged" />
      </div>
    </div>
    <br />
  </div>
  <DynamicForm ref="form" @submitted="formSubmitted" />
</template>
<script setup>
// import all the vue stuff
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch, onBeforeMount } from "vue";
// load firebase helper
import { db } from "../../db.js";
// Load components
import { Loading, IconButton } from "@comp/index.js";
import AttachableTables from "../AttachableTables/AttachableTables.vue";
import DynamicForm from "../DynamicForm/DynamicForm.vue";
import Fields from "../Fields/Fields.vue";
// extra
import { get, has } from "lodash";

import standardMenuActions from "../../views/standardMenuActions";
import { addToRecents } from "../../utils.js";
import { useToast } from "vue-toastification";

// inistalise vue objects
const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();
let routeMeta = ref(null);
// make them accessable in the template
const currentRoute = computed(() => route);
const dataFields = computed(() => get(routeMeta.value, "dataFields"));
const aiTree = computed(() => get(routeMeta.value, "attachedItems"));
const menuActions = computed(() => {
  let ma = get(routeMeta.value, "menuActions", []);
  return [...ma, standardMenuActions.openInFirebase];
});

// set the component refs
let contextMenu = ref(null);
let form = ref(null);
let tables = ref(null);
let topComponent = ref(null);
let selectedTableIndex = ref(100);
let showCancelled = ref(false);

// init page item and attached items
let loaded = ref(false);
let loading = ref(true);

// get the path for the ltem data location
let itemLocation = computed(() =>
  currentRoute.value.meta.dataPathOverride
    ? currentRoute.value.meta.dataPathOverride.replace(
      ":id",
      currentRoute.value.params.id
    )
    : currentRoute.value.path
);

let item = computed(() => {
  return store.state[itemLocation.value];
});

let attachedItems = ref({});

// init context data, this object is passed to the page configs, attached items configs and all actions
let contextData = computed(() => {
  return {
    pageItem: computed(() => itemOverride.value).value,
    currentRoute: currentRoute.value,
    form: form.value,
    contextMenu: contextMenu.value,
    store,
    router,
    db,
    toast,
    meta: routeMeta?.value?.formMetadata,
    attachedItems: tables?.value?.getAttachedItems(),
  };
});

let itemOverride = computed(() => {
  return currentRoute.value.meta.itemOverride
    ? currentRoute.value.meta.itemOverride(item.value, contextData.value)
    : item.value;
});

function formSubmitted() {
  tables.value?.recalculateWarnings();
};

async function getItemAndAttachedItems(time = 0) {

  try {
    await getLazyMeta();

    // set loading to true
    loading.value = true;
    await wait(time);
    // First listen to the item
    if (currentRoute.value.meta && currentRoute.value.meta.watch !== false) await db.watch(itemLocation.value, store);
    // add to recents
    addToRecents(contextData.value);

    // if no attached items then kill function here
    if (!has(routeMeta.value, "attachedItems")) {
      loading.value = false;
      return;
    }

    loading.value = false;
    loaded.value = true;
  } catch (err) {
    console.error(err);
  }

  setTimeout(() => {
    loading.value = false;
  }, 8000);
}

const selectedTableIndexChanged = (number) => {
  selectedTableIndex.value = number;
};

function wait(time = 0) {
  return new Promise((res) => {
    setTimeout(res, time);
  });
}

async function getLazyMeta() {
  let meta = !!currentRoute.value.meta.lazyLoadMeta ? await currentRoute.value.meta.lazyLoadMeta() : {};
  routeMeta.value = {
    ...currentRoute.value.meta,
    ...meta,
  };
}

watch(currentRoute.value, getItemAndAttachedItems);
watch(item, () => {
  loaded.value = false;


  getItemAndAttachedItems();
});
// watch(store.state, getItemAndAttachedItems);
onBeforeMount(async () => {
  if (currentRoute.value.meta.onBeforeMount) {
    await currentRoute.value.meta.onBeforeMount();
  }
  await getLazyMeta();
});
onMounted(getItemAndAttachedItems);
</script>
