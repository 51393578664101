import {
    DateValue
} from "@comp/index.js";
import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import changeRequestAttachable from "../ChangeRequests/attachable";
import {
    reviseWorkInstruction
} from "./actions";

export default [{
    "path": "/Home/WorkInstructions",
    "component": { ...ItemListView },
    "name": "Work Instructions",
    "meta": {
        "app": "Home",
        "name": "Work Instructions",
        "sideNav": true,
        "sideNavSection": "Forms, Handbooks & Work Instructions",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "WorkInstructions",
        "dataPath": "Database/WorkInstructions",
        "selectionRoute": "Home/WorkInstructions/{ID}",
        "menuActions": [],
        lock(user) {
            return [user?.Security?.Database?.Read !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Home/WorkInstructions/:id",
    "component": ItemView,
    "name": "WorkInstruction",
    "meta": {
        "app": "Home",
        "name": "WorkInstructios",
        "dataPath": "Database/WorkInstructions",
        "dataPathOverride": "Database/WorkInstructions/:id",
        "selectionRoute": "Database/WorkInstructions/{ID}",
        "storeData": ["Database/MFARQuestions"],
        title(item) {
            return `Work Instruction: ${item.Number}`;
        },
        "attachedItems": {
            "More Data": {
                "attachables": [{
                    "label": "Revisions",
                    "key": "Revisions",
                    "local": true,
                    "headers": [{
                        "headerName": "Revision",
                        "field": "Revision",
                        "sort": "asc",
                        "type": "number",
                        "filter": "agNumberColumnFilter"
                    }, {
                        "headerName": "Date",
                        "field": "Date",
                        "filter": "agDateColumnFilter",
                        "cellRenderer": DateValue
                    }],
                    "rowActions": [],
                    "actions": [{
                        "label": "Revise Work Instruction",
                        "action": reviseWorkInstruction
                    }]
                }, changeRequestAttachable]
            }
        },
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "col-span-2"
        }, {
            "label": "Revision",
            "key": "Revision"
        }, {
            "label": "Handbook",
            "key": "Handbook",
            "link": "Home/Handbooks/:HandbookID"
        }],
        "menuActions": []
    }
}];
