import {
    appConfig,
    makeAttached,
    makeDropdown,
    pad
} from "../../../utils.js";

import add from "date-fns/add";
import {
    addGreenCard
} from "../NonCons/actions.js";
import {
    get
} from "lodash";

export const addNonCon = ({ form, pageItem, store }) => {
    form.setConfig({
        "title": "Add NonCon",
        "type": "push",
        "location": "Audits/SubNonConformities",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}SUB-NC-${pageItem.Number.split('-')[2]}-${pad(Object.keys(pageItem.SubNonConformities || {}).length + 1, 2)}`
            }
        }, {
            "label": "Severity",
            "name": "Severity",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityLevels",
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityCategories",
        }],
        preSubmitHook({ liveInputData, dropdownData }) {
            let selected = dropdownData.Severity.find(i => i.id === liveInputData.Severity);
            return {
                "Comment": "",
                "Completed": false,
                "CorrectiveAction": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false
                },
                "Cycle": pageItem.Cycle,
                "Date": {
                    "Completed": "N/A",
                    "Due": add(new Date(), { days: selected.meta.Days }).toISOString(),
                    "HOD": "N/A",
                    "Initial": add(new Date(), { days: selected.meta.Days }).toISOString()
                },
                "Extension": {
                    "Granted": false,
                    "Requested": false
                },
                "Issue": {
                    "Comment": "",
                    "HOD": false
                },
                "Number": `${appConfig.docPrefix}SUB-NC-${pageItem.Number.split('-')[2]}-${pad(Object.keys(pageItem.SubNonConformities || {}).length + 1, 2)}`,
                "Overdue": false,
                "PreventativeAction": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false,
                },
                "Procedure": pageItem.Procedure,
                "ProcedureID": pageItem.ProcedureID,
                "Report": pageItem.Number,
                "ReportID": pageItem.ID,
                "RootCause": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false
                }
            }
        },
        async postSubmitHook({ db, data, typeRef }) {
            await db.set(`Audits/Procedures/${pageItem.ProcedureID}/SubNonConformities/${typeRef.key}`, true);
            await db.set(`Audits/SubReports/${pageItem.ID}/NonConformities/${typeRef.key}`, true);
            await addGreenCard({ store, db, pageItem, data, typeRef }, "Audits/SubNonConformities/");
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editIssueType = path => ({ form, pageItem }) => {

    const splitPath = path.split(".");
    const name = path.replace(".", "/");

    form.setValues({
        [name]: get(pageItem, path)
    });

    form.setConfig({
        "title": `Edit ${splitPath[0]}`,
        "type": "update",
        "location": `Audits/SubNonConformities/${pageItem.ID}`,
        "fields": [{
            "label": splitPath[0],
            "type": "richtext",
            "divClass": "col-span-3",
            "name": name
        }]
    });

    form.toggle();
};

export const accept = (who, type) => ({ form, pageItem }) => {
    form.setConfig({
        "title": `${who}: Accept ${type}`,
        "type": "update",
        "location": `Audits/SubNonConformities/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                [`${type}/${who}`]: true,
                [`Date/${type}/${who}`]: (new Date()).toISOString()
            };
        },
        async postSubmitHook({ db }) {
            let data = await db.once(`Audits/SubNonConformities/${pageItem.ID}`);

            if (
                data.CorrectiveAction.HOD &&
                data.CorrectiveAction.DAM &&
                data.RootCause.HOD &&
                data.RootCause.DAM &&
                data.PreventativeAction.HOD &&
                data.PreventativeAction.DAM &&
                !data.Completed
            ) {
                db.update(`Audits/SubNonConformities/${pageItem.ID}`, {
                    "Completed": true,
                    "Date/Completed": (new Date()).toISOString()
                });
                for (let jobCard of Object.keys(pageItem.JobCards || {})) {

                    await db.update(`Database/JobCards/${jobCard}`, {
                        "Signedoff": {
                            "Engineer": true,
                        },
                        "Date": {
                            "Signedoff": {
                                "Engineer": (new Date()).toISOString()
                            }
                        }
                    })
                }
            }
        }
    });
    form.toggle();
}

export const requestExtension = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Request a Sub Non Conformity Due Date Extension",
        "type": "update",
        "location": `Audits/SubNonConformities/${pageItem.ID}`,
        "fields": [{
            "label": "Reason",
            "name": "Comment"
        }],
        preSubmitHook() {
            return {
                "Extension/Granted": false,
                "Extension/Requested": true,
                "Date/Extension/Requested": (new Date()).toISOString(),
                "Overdue": (new Date()).getTime() > (new Date(pageItem.Date.Due)).getTime()
            }
        }
    });
    form.toggle();
};

export const grantExtension = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Grant Sub Non Conformity Due Date Extension",
        "type": "update",
        "location": `Audits/SubNonConformities/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Extension/Granted": true,
                "Date/Extension/Granted": (new Date()).toISOString(),
                "Overdue": (new Date()).getTime() > (new Date(pageItem.Date.Due)).getTime()
            }
        }
    });
    form.toggle();
};
