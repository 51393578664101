<template>
  <div class="md:overflow-hidden h-screen">
    <div class="flex flex-col md:flex-row">
      <div class="p-2 flex flex-grow overflow-y-auto h-screen" v-if="!failedUser && !authenticated && !route.meta.open">
        <div class="w-full flex flex-col">
          <div class="m-2 p-2 bg-white rounded shadow flex flex-col">
            <Loading message="One moment please..." />
          </div>
        </div>
      </div>
      <LoginBox :appConfig="appConfig" :auth="auth" v-if="failedUser && !authenticated && !route.meta.open"
        @reset="toast.success('Password reset email sent')" @error="toast.error($event.message)">
        <img class="mt-4 mb-2 mx-auto" :src="imageURL()" alt="" width="180" />
      </LoginBox>
      <SideNav :show-nav="authenticated && !route.meta.open" :shownApps="state.shownApps"
        :shownRoutes="state.shownRoutes" @recents="$refs.dbRecents.toggle()" @search="$refs.dbSearch.toggle()"
        showButtons>
        <template v-slot:buttons>
          <IconButton icon="arrows-up-to-line" @click="store.state.sideNavOpen = false" />
          <button class="primary flex-grow" v-tooltip="'ALT + R'" @click="$emit('recents')">
            <font-awesome-icon class="mr-2" icon="sync-alt"></font-awesome-icon>
            Recents
          </button>
          <button class="primary flex-grow" v-tooltip="'ALT + F'" @click="$emit('search')">
            <font-awesome-icon class="mr-2" icon="search"></font-awesome-icon>
            Search
          </button>
        </template>
        <template v-slot:head>
          <img class="mt-4 mb-2 mx-auto" :src="imageURL()" alt="" width="180" />
        </template>
        <template v-slot:foot>
          <IconButton icon="sign-out-alt" @click="signout" />
        </template>
      </SideNav>
      <div :class="`${store.state.sideNavOpen ? 'pt-2' : 'pt-16'}  p-2 flex flex-grow md:overflow-y-auto h-screen`"
        v-if="authenticated && !route.meta.open">
        <router-view></router-view>
      </div>
      <div class="md:p-8 flex flex-grow overflow-y-auto h-screen" v-if="route.meta.open === true">
        <router-view name="open-forms"></router-view>
      </div>
    </div>
    <DatabaseSearch ref="dbSearch" />
    <DatabaseRecents ref="dbRecents" />
  </div>
</template>

<script setup>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { LoginBox, SideNav, IconButton, Loading } from "@comp/index.js";
import { db, auth } from "./db.js";
import { groupBy, sortBy, get } from "lodash";
import DatabaseSearch from "./views/DatabaseSearch.vue";
import DatabaseRecents from "./views/DatabaseRecents.vue";
// import DatabaseHelp from "./components/DatabaseRecents.vue";
import { useToast } from "vue-toastification";

const toast = useToast();
// use the store to determine the authenticated state
const store = useStore();

const router = useRouter();
const route = useRoute();

const currentApp = computed(() => route.meta.app);
const failedUser = computed(() => store.state.failedUser);

let state = reactive({
  shownApps: computed(() => {
    // Create a computed property that returns the filtered and sorted list of routes that are to be shown in the app switcher.
    return router
      // Get all the routes.
      .getRoutes()
      // Flat the array of routes to a single level.
      .flat(Infinity)
      // Filter the routes based on whether they have a "appSwitcher" meta property.
      .filter((route) => {
        return route.meta?.appSwitcher;
      })
      // Filter the routes based on the security requirement.
      .filter((route) => {
        if (!route.meta?.security) return true;
        else
          return get(
            store?.state?.user?.Security,
            route?.meta?.security,
            false
          );
      })
      // Sort the routes based on their app switcher position.
      .sort((a, b) => {
        const aPos = a.meta.appSwitcherPosition;
        const bPos = b.meta.appSwitcherPosition;
        return aPos > bPos ? 1 : bPos > aPos ? -1 : 0;
      });
  }),
  shownRoutes: computed(() => {
    // Create a computed property that returns the sorted and grouped list of routes that are to be shown in the side navigation.
    let sr = sortBy(
      // Sort the list of routes based on their side navigation position.
      router.getRoutes().filter((_route) => {
        const isCurrentApp = _route.meta?.app === currentApp.value;
        const isSideNav = _route.meta?.sideNav;
        const isNotHidden = _route.meta.hidden && typeof _route.meta?.hidden === "function" ? !_route.meta.hidden(store.state.user) : true;
        return isCurrentApp && isSideNav && isNotHidden;
      }),
      "meta.sideNavPosition"
    ).map((_route) => {
      // Check if the route is locked and set the "locked" property accordingly.
      const locked =
        _route.meta.lock && typeof _route.meta?.lock === "function"
          ? _route.meta.lock(store.state.user)
          : [false, ""];

      const warning =
        _route.meta.warning && typeof _route.meta?.warning === "function"
          ? _route.meta.warning(store.state.user)
          : [false, ""];

      return {
        ..._route,
        locked: locked,
        warning: warning,
      };
    });
    // Group the list of routes based on their side navigation section.
    return groupBy(sr, (i) => i.meta.sideNavSection || "catch all");
  }),
});

const authenticated = computed(() => store.getters.authenticated);
const appConfig = computed(() => store.state.appConfig);

// define the components in vue
const dbSearch = ref(null);
const dbRecents = ref(null);

// get the correct image url
const imageURL = () => {
  const imgURL = new URL(
    `./assets/${appConfig.value.appPrefix}_logo_main.png`,
    import.meta.url
  );
  return imgURL;
};

// watch the auth state from auth / db
auth.onAuthStateChanged(manageUserAuthenticationState);

async function manageUserAuthenticationState(user) {

  // If no user data is passed then set the store auth state to false and empty the user object

  if (!user) {
    store.commit("save", {
      user: null,
    });
    localStorage.removeItem("expectSignIn");
    return;
  }
  // USER FOUND
  // get and store the user object
  db.watch("counts", store);
  let userd = await db.watch(`Userdata/${user.uid}`, store, "user");

  if (!userd) {
    toast.error("User not found, you may not have access to this application");
    return;
  }

  if (userd?.Security?.Audits?.Read === true) {
    const file = await db.once("Audits/Settings/Handbook", store, "handbook");
    const handbookURL = await db.download(file);
    store.commit("save", {
      handbook: handbookURL,
    });
  }

  if (userd?.Security?.Leave?.Read === true) {

    // get and store the leave objects
    let today = new Date();
    let currentTime = today.getTime();
    let upcomingAndRecentLeaveEvents = await db
      .ref("Leave/Events")
      .orderByChild("Date/Time")
      .startAfter(currentTime - 1000 * 60 * 60 * 24 * 21)
      .endBefore(currentTime + 1000 * 60 * 60 * 24 * 21)
      .once("value");

    if (upcomingAndRecentLeaveEvents.val() === null) return;

    let events = Object.values(upcomingAndRecentLeaveEvents.val() || {}).reduce((acc, i) => {
      const ids = Object.keys(i.Users || {});
      for (const id of ids) {

        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(i);
      };
      return acc;
    }, {});

    store.commit("save", {
      upcomingAndRecentLeaveEvents: events,
    });
  }

  // get all the users and store them
  db.store("users", store);
  db.store("business/leave/events", store);

  // set localstore handler
  localStorage.setItem("expectUserSignIn", 1);
}
const signout = () => {
  auth.signOut();
};

onkeydown = function (e) {
  if (e.altKey && e.key == "f") {
    e.preventDefault();
    //your saving code
    dbSearch.value.toggle();
  }

  if (e.altKey && e.key == "r") {
    e.preventDefault();
    //your saving code
    dbRecents.value.toggle();
  }
};
</script>
