import {
} from "@comp";

import HTMLTooltip from "./HTMLTooltip.vue";
import {
    buildLimitations
} from "./utils";

export default function (store) {
    const panels = Object.entries(store.state["Matrices/SignatoryMatrix/Panels"]);
    const panelColumns = panels.map(([panelId, panel]) => {
        console.log(panel);
        
        const panelNumberArray = panel.Name.match(/\(Panel([^)]+)\)/);
        // console.log(panelNumberArray);
        
        const panelNumber = panelNumberArray[1]

        // const panelNumber = panel.Number;
        return {
            headerName: `Panel ${panelNumber}`,
            type: 'cve',
            field: `Panels.${panelId}.Answer`,
            minWidth: 85,
            width: 85,
            hide: !panel.STCApproved,
            headerTooltip: panel.Name,
            hide: false
        };
    });

    return {
        tooltipInteraction: true,
        tooltipShowDelay: 0,
        suppressHorizontalScroll: false,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            cellDataType: false,
            suppressFieldDotNotation: false,
            useValueFormatterForExport: true,
            useValueParserForImport: true,
            tooltipComponent: HTMLTooltip,
            tooltipShowDelay: 0,
            tooltipInteraction: true
        },
        columnDefs: [{
            headerName: "Person",
            children: [{
                headerName: "TSR",
                field: "CSR",
                pinned: "left",
                width: 70,
                filter: 'agNumberColumnFilter',
                type: "number",
                sort: "desc",
                sortIndex: 0
                
            }, {
                headerName: "Name",
                field: "Name",
                pinned: "left",
            }, {
                headerName: "Internal",
                field: "IsInternal",
                pinned: "left",
                width: 70,
                valueFormatter(params) {
                    return params.data.IsInternal ? "Yes" : "No";
                }
            }, {
                headerName: "Limitations",
                field: "Limitations",
                pinned: "left",
                type: "html",
                valueFormatter(params) {
                    return "Hover / Click to see limitations";
                },
                tooltipValueGetter(params) {
                    return buildLimitations(params.data, store);
                }
 
            }]
        }, {
            headerName: "Discipline: Areas of Expertise",
            children: panelColumns
        }]
    };
};
