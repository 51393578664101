// Add the Firebase products that you want to use
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Application Config
const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

if (firebase.apps.length === 0) {
  firebase.initializeApp(appConfig.firebaseConfig);
}

const $db = firebase.database();
const $storage = firebase.storage();
const auth = firebase.auth();

// list of currently active firebase watchers
const firebaseWatchers = new Set();

const db = {
  async once(location) {
    const ref = await $db.ref(location).once("value");
    return ref.val();
  },
  async keys(location, keys) {
    const data = await Promise.all(keys.map(key => $db.ref(location + key).once("value")))
    return data.map(d => d.val());
  },
  async query(location, key, value, store, storeKey) {
    const ref = await $db.ref(location).orderByChild(key).equalTo(value).once("value");
    store.commit("save", {
      [storeKey || location]: ref.val()
    });
    return ref.val();
  },
  async between(location, key, value1, value2) {
    const ref = await $db.ref(location).orderByChild(key).startAt(value1).endAt(value2).once("value");
    return ref.val();
  },
  async store(location, store, storeKey) {
    const ref = await $db.ref(location).once("value");
    store.commit("save", {
      [storeKey || location]: ref.val()
    });
    return ref.val();
  },
  async update(location, data) {
    return await $db.ref(location).update(data);
  },
  async set(location, data) {
    return await $db.ref(location).set(data);
  },
  async delete(location) {
    return await $db.ref(location).remove();
  },
  async push(location, data) {
    let ref = await $db.ref(location).push(data);
    ref.child("ID").set(ref.key);
    return ref;
  },
  async watch(location, store, storeKey) {
    return await new Promise((resolve, reject) => {
      try {

        if (firebaseWatchers.has(location)) {
          resolve(store.state[storeKey || location]);
          return;
        }
        else {
          firebaseWatchers.add(location)
          $db.ref(location).on("value", ref => {
            store.commit("save", {
              [storeKey || location]: ref.val()
            });
            resolve(store.state[storeKey || location]);
          }, (err) => {
            reject(err);
          });
        }
      } catch (err) {
        console.error(err);
      }
    });
  },
  async download(location) {
    return await $storage.ref(location).getDownloadURL()
  },
  async upload(location, file, metadata) {
    return await $storage.ref(location).child(file.name || metadata.name).put(file, { "customeMetadata": metadata });
  },
  async remove(location, file) {
    return await $storage.ref(location).child(file).delete();
  },
  unwatch(location) {
    $db.ref(location).off("value");
    firebaseWatchers.delete(location);
  },
  ref(location) {
    return $db.ref(location);
  },
  utils: {
    async addAllTo(data, location, key, target) {
      for (const k of Object.keys(data[key])) {
        await $db.ref(`${location}/${k}/${target}/${data.ID}`).set(true);
      }
    },
    async upCount(location) {
      const numberCount = parseInt(await db.once(location));
      await db.set(location, numberCount + 1);
    },
    async updateRefs(data, location, keys) {
      for (const key of keys) {
        await $db.ref(`${location}/${data.ID}/${key}`).set(data[key] || "");
      }
    }
  }
}


export {
    db,
    auth
  };
