<template>
  <div id="side-nav" :class="`
      ${store.state.sideNavOpen ? 'max-h-screen overflow-y-auto min-w-xs shadow flex-col bg-white w-full fixed top-0 bottom-0 md:static md:flex md:max-w-xs md:max-h-screen z-50' :
      'max-h-14 overflow-hidden min-w-xs shadow bg-white w-full fixed top-0 bottom-0 z-50'}

       `" v-show="showNav">
    <IconButton icon="bars" @click="store.commit('save', { 'sideNavOpen': !store.state.sideNavOpen })"
      v-if="!store.state.sideNavOpen">{{ store.state.sideNavOpen }}</IconButton>
    <slot name="head" v-if="store.state.sideNavOpen || isLargeScreen"></slot>
    <div v-if="hideAppSwitcher === false" class="
       grid 
       grid-cols-7
        place-items-center
        bg-gradient-to-br
        from-gray-100
        to-gray-150
        shadow-inner
        border-t border-b border-gray-100
        mx-4
        my-2
        px-2
        rounded-lg
      ">
      <AppIcon v-for="_app in shownApps" :key="_app.name" :icon="_app.meta.icon" :selected="currentApp === _app.meta.app"
        :tt="_app.meta.app" @click="nav(_app)" />
    </div>
    <hr />

    <div class="overflow-y-auto">
      <!-- <hr class="w-full text-gray-200 bg-gray-200" style="height: 2px" /> -->
      <div v-for="(routes, key) in shownRoutes" :key="key">
        <p class="mx-4 mt-2 text-primary font-medium text-xs">{{ key }}</p>

        <div class="
            rounded-lg
            bg-gradient-to-br
            from-gray-100
            to-gray-150
            shadow-inner
            mx-4
            my-2
          ">
          <router-link
            :class="` transition duration-300 ease-in-out flex flex-row items-center text-sm pl-3 pb-0 pt-2 last:pb-2 ${_route.locked[0] === true ? 'pointer-events-none' : ''}`"
            v-for="_route in routes" :key="_route.name" :to="_route.path" @click="shouldCloseOnNav">
            <p :class="`flex flex-grow hover:text-primary hover:font-medium transition ${_route.name === currentRoute
              ? 'text-primary font-medium pl-2'
              : 'text-text'
              }`" v-if="_route.locked[0] === false">
              {{ _route.name }}
            </p>
            <div v-if="_route.locked[0] === true" class="
                flex flex-row
                items-center
                rounded
                pl-2
              " v-tooltip="{ content: _route.locked[1] }">
              <font-awesome-icon class="text-gray-300" icon="lock"></font-awesome-icon>
              <div class="ml-2 text-gray-300">{{ _route.name }}</div>
            </div>

            <div v-if="_route.warning[0] === true" class="
                flex flex-row
                items-center
                rounded
                pl-2
              " v-tooltip="{ content: _route.warning[1] }">
              <font-awesome-icon icon="exclamation-circle" class="text-orange mr-4"></font-awesome-icon>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <span class="flex flex-grow"></span>
    <hr />
    <div class="flex flex-row" v-if="showButtons">
      <IconButton icon="arrows-up-to-line" @click="store.state.sideNavOpen = false" />
      <button class="primary flex-grow" v-tooltip="'ALT + R'" @click="$emit('recents')" v-show="hideButttons === false">
        <font-awesome-icon class="mr-2" icon="sync-alt"></font-awesome-icon>
        Recents
      </button>
      <button class="primary flex-grow" v-tooltip="'ALT + F'" @click="$emit('search')" v-show="hideButttons === false">
        <font-awesome-icon class="mr-2" icon="search"></font-awesome-icon>
        Search
      </button>
    </div>
    <hr />
    <div class="flex flex-row" v-if="currentUser">
      <p class="p-4 flex-grow text-sm">
        Welcome <span class="font-medium">{{ currentUser }}</span>
      </p>
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script setup="props">
import { computed, defineProps, onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useMediaQuery } from '@vueuse/core'

import AppIcon from "./AppIcon.vue";
import IconButton from "../IconButton/IconButton.vue";

// Setup prop
const props = defineProps({
  "showNav": Boolean,
  "showButtons": Boolean,
  "shownApps": Array,
  "shownRoutes": Object,
  "hideButttons": {
    type: Boolean,
    default: false
  },
  hideAppSwitcher: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const currentUser = computed(() => store.getters.userName);
const route = useRoute();
const router = useRouter();
const currentRoute = computed(() => route.meta.name);
const currentApp = computed(() => route.meta.app);
const isLargeScreen = useMediaQuery('(min-width: 768px)')

function nav(_app) {
  router.push(_app.path);
  shouldCloseOnNav();
}

function shouldCloseOnNav() {
  if (isLargeScreen.value === false) {
    store.commit("save", { "sideNavOpen": false })
  }
}



onMounted(() => {
  store.commit("save", { "sideNavOpen": isLargeScreen.value })
});

</script>
