import {
    files as _files,
    revisions as _revisions
} from "../../standardAttachables.js";
import {
    addArrangement,
    addFile,
    addVendor,
    addVendorFile,
    editArrangement,
    editArrangementStatus,
    editVendor,
    removeFile,
    removeVendorFile,
    reviseArrangement
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import Settings from "../Settings/Settings.vue";
import _designChangeAttachable from "../DesignChanges/attachable.js";
import { appConfig } from "../../../utils";
import arrangementAtt from "./attachable";
import {
    standardDateLocal
} from "../../../utils";
import store from "../../../store";
import types from "./settings/types.js";
import { vendorAudits } from "./internalAttachables";

const revisions = {
    ..._revisions
};

revisions.headers[0].sort = "desc";

revisions.actions = [{
    "label": "Revise",
    "action": reviseArrangement
}]

let files = {
    ..._files
};

let files2 = {
    ..._files
};

files.actions = [{
    "label": "Add File",
    "action": addFile
}];

files.rowActions = [{
    "label": "Download File",
    async action({ db, rowItem, pageItem }) {
        let url = await db.download(`Database/Arrangements/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.File}`);
        window.open(url, "__blank");
    }
}, {
    "label": "Remove File",
    "action": removeFile,
    "break": true,
    "class": "text-red font-bold"
}];

files2.actions = [{
    "label": "Add File",
    "action": addVendorFile
}];

files2.rowActions = [{
    "label": "Download File",
    async action({ db, rowItem, pageItem }) {
        let url = await db.download(`Database/Vendors/${pageItem.ID}/Files/${rowItem.ID}/${rowItem.File}`);
        window.open(url, "__blank");
    }
}, {
    "label": "Remove File",
    "action": removeVendorFile,
    "break": true,
    "class": "text-red font-bold"
}];


const designChangeAttachable = { ..._designChangeAttachable };

function outdated(item) {



    if (!store?.state["Database/ArrangementTypes"]) {
        return false;
    }

    const type = Object.values(store.state["Database/ArrangementTypes"]).find(x => x.ID === item.TypeID);


    if (parseInt(type.Months) === 0) return false; // Never expire

    let today = new Date();
    let reviewDate = new Date(item.Date.Review || item.Date.Created);
    reviewDate.setMonth(reviewDate.getMonth() + parseInt(type.Months));
    return today.getTime() > reviewDate.getTime();
};

function getDueDate(item){
    if(!store) return "";
    let isOutdataed = outdated(item);
    let output = "";
    if (item.Status !== "Approved") return "Not Approved";

    let type = Object.values(store.state["Database/ArrangementTypes"]).find(x => x.ID === item.TypeID);


    if (isOutdataed) return `Not Valid - Expired on ${new Date(item.Date.Review).toLocaleDateString("en-gb", standardDateLocal)}`;
    else if (type.Months === "0") output += "Valid indefinitely";
    else {
        
        let nextReview = new Date(item.Date.Review);
        nextReview.setMonth(nextReview.getMonth() + parseInt(type.Months || 0));
        output += `${nextReview.toLocaleDateString("en-gb", standardDateLocal)}`;
    }

    return output;
}

let additionalTableHeaders = [{
    "headerName": "Valid",
    "field": "Date.Review",
    "colId": "valid",
    "flex": 1,
    "hide": false,
    "pivot": false,
    "rowGroup": false,
    "type": "text",
    "useValueFormatterForExport": true,
    "width": 310,
    valueFormatter(params) {
        return outdated(params.data) === false ? "Yes" : "No";
    }
}, {
    "headerName": "Due Date",
    "field": "CurrentCycleID",
    "colId": "Due Date",
    "flex": 1,
    "hide": false,
    "pivot": false,
    "rowGroup": false,
    "type": "text",
    "useValueFormatterForExport": true,
    "width": 310,
    valueFormatter(params) {
        return getDueDate(params.data);
    }
}];

const allContractorActions = [{
    "label": "Add Arrangement",
    "action": addArrangement
}];

export default [{
    "path": "/Database/Arrangements",
    "component": { ...ItemListView },
    "name": "All Contractor Arrangements",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "All Contractor Arrangements",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "security": "Database.Read",
        "sideNavPosition": 0,
        "appSwitcher": true,
        "appSwitcherPosition": 2,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a02", "--a07", "--a05", "--a10", "POA", "--a12", "--a05"].includes(item.TypeID) && !outdated(item);
        }
    }
}, {
    "path": "/Database/Arrangements-CAMO",
    "component": { ...ItemListView },
    "name": "CAMO's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "CAMO's",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 1,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "menuActions": allContractorActions,
        "selectionRoute": "Database/Arrangements/{ID}",
        filter(item) {
            return item.TypeID === "--a02" && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-MOA/",
    "component": { ...ItemListView },
    "name": "MOA's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "MOA's",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 2,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "menuActions": allContractorActions,
        "selectionRoute": "Database/Arrangements/{ID}",
        filter(item) {
            return item.TypeID === "--a07" && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-POA/",
    "component": { ...ItemListView },
    "name": "POA's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "POA's",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 3,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "menuActions": allContractorActions,
        "selectionRoute": "Database/Arrangements/{ID}",
        filter(item) {
            return item.TypeID === "--a10" && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-DOA/",
    "component": { ...ItemListView },
    "name": "DOA's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "DOA's",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 3,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "menuActions": allContractorActions,
        "selectionRoute": "Database/Arrangements/{ID}",
        filter(item) {
            return item.TypeID === "--a05" && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-EXA",
    "component": { ...ItemListView },
    "name": "External Arrangements",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "External Arrangements",
        "sideNav": true,
        "sideNavSection": "Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 4,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "menuActions": allContractorActions,
        "selectionRoute": "Database/Arrangements/{ID}",
        filter(item) {
            return item.TypeID === "--a12" && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-SubContractors",
    "component": { ...ItemListView },
    "name": "All Sub-Contractor Arrangements",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "All Sub-Contractor Arrangements",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "sideNavPosition": 0,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a11", "--a09", "--a04", "--a06"].includes(item.TypeID) && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-DO",
    "component": { ...ItemListView },
    "name": "DO's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "DO's",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 1,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a04"].includes(item.TypeID) && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-MDO",
    "component": { ...ItemListView },
    "name": "MDO's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "MDO's",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "sideNavPosition": 1,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a06"].includes(item.TypeID) && !outdated(item)
        },
        hidden() {
            return appConfig.appPrefix !== "doa";
        }
    }
}, {
    "path": "/Database/Arrangements-IND",
    "component": { ...ItemListView },
    "name": "IND's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "IND's",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "sideNavPosition": 2,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a11"].includes(item.TypeID) && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-TH",
    "component": { ...ItemListView },
    "name": "TH's",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "TH's",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "sideNavPosition": 2,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a09"].includes(item.TypeID) && !outdated(item)
        }
    }
}, {
    "path": "/Database/Arrangements-expired",
    "component": { ...ItemListView },
    "name": "Expired Contractors",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "Expired Contractors",
        "sideNav": true,
        "sideNavSection": "Expired Contractors",
        "sideNavPosition": 0,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a02", "--a07", "--a05", "--a10", "POA", "--a12", "--a05"].includes(item.TypeID) && outdated(item);
        }
    }
}, {
    "path": "/Database/Arrangements-SubContractors-expired",
    "component": { ...ItemListView },
    "name": "Expired Sub Contractors",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "Expired Sub Contractors",
        "sideNav": true,
        "sideNavSection": "Expired Contractors",
        "sideNavPosition": 0,
        "icon": "file-contract",
        "tableSettings": "Arrangements",
        "dataPath": "Database/Arrangements",
        "additionalTableHeaders": [
            ...additionalTableHeaders
        ],
        "selectionRoute": "Database/Arrangements/{ID}",
        "menuActions": allContractorActions,
        filter(item) {
            return ["--a11", "--a09", "--a04"].includes(item.TypeID) && outdated(item);
        }
    }
}, {
    "path": "/Database/Arrangements/:id",
    "component": ItemView,
    "name": "Arrangement",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes", "Database/Vendors"],

        "name": "All Contractor Arrangements",
        "dataPath": "Database/Arrangements",
        "selectionRoute": "Database/Arrangements/{ID}",
        title(item) {
            return `Arrangement: ${item?.Number}`
        },
        "menuActions": [{
            "label": "Edit",
            "action": editArrangement
        }, {
            "label": "Review & Status",
            "action": editArrangementStatus
        }, {
            "label": "Revise",
            "action": reviseArrangement
        }, {
            "label": "Add File",
            "action": addFile
        }],
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Revision",
            "key": "Revision"
        }, {
            "label": "Approval - Valid Until",
            compute({ item, store }) {
                return getDueDate(item, store);
            }
        }, {
            "label": "Arrangement With",
            "key": "With"
        }, {
            "label": "Arrangement Requested By",
            "key": "Requested"
        },
        {
            "label": "Scope",
            "key": "Scope",
            "class": "md:col-span-3"
        }, {
            "label": "Notes",
            "key": "Notes",
            "class": "md:col-span-3"
        }],
        "attachedItems": {
            "Attached Items": {
                "attachables": [revisions, designChangeAttachable, files, vendorAudits]
            }
        },
        "formMetadata": {
            "ParentType": "Arrangements"
        }
    }
}, {
    "path": "/Database/Vendors",
    "component": { ...ItemListView },
    "name": "Vendor Audits",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "Vendor Audits",
        "sideNav": true,
        "sideNavSection": "Sub Contractors",
        "tableSettings": "Vendors",
        "dataPath": "Database/Vendors",
        "selectionRoute": "Database/Vendors/{ID}",
        "menuActions": [{
            "label": "Add Vendor",
            "action": addVendor
        }]
    }
}, {
    "path": "/Database/Vendors/:id",
    "component": ItemView,
    "name": "Vendor",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],

        "name": "Vendor",
        "dataPath": "Database/Vendors",
        "selectionRoute": "Database/Vendors/{ID}",

        "formMetadata": {
            "ParentType": "Vendors"
        },
        title(item) {
            return `Vendor: ${item?.Name}`
        },
        "menuActions": [{
            "label": "Edit",
            "action": editVendor
        }],
        "dataFields": [{
            "label": "Name",
            "key": "Name"
        }, {
            "label": "Notes & Limitations",
            "key": "Notes",
            "class": "md:col-span-3"
        }
        ],
        "attachedItems": {
            "Attached Arrangements": {
                "attachables": [arrangementAtt, files2]
            }
        }
    }
}, {
    "path": "/Database/ArrangmentSettings",
    "component": Settings,
    "name": "Arrangement Settings",
    "meta": {
        "app": "Arrangements",
        "storeData": ["Database/ArrangementTypes"],
        "name": "Arrangement Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Arrangement Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [types]
    }
}];
