import {
    addDesignChange,
    editDesignChange,
    linkDesignChange,
    importDesignChangesJobCards,
    unlinkDesignChange,
    addTransferredDesignChange
} from "./actions";

export default {
    "label": "Design Changes",
    "key": "DesignChanges",
    "location": "Database/DesignChanges/",
    "actions": [{
        "label": "Add Design Change",
        "action": addDesignChange,
        show({ meta }) {
            return meta?.ParentType === "Commercials" ? [true, ''] : [false, 'Can only add a new Design Change to a Commercial']
        }
    },
        {
            "label": "Add Transferred Design Change",
            "action": addTransferredDesignChange,
            show({ meta }) {
                return meta?.ParentType === "Commercials" ? [true, ''] : [false, 'Can only add a new Design Change to a Commercial']
            }
        },
    {
        "label": "Link Design Change",
        "action": linkDesignChange,
        show({ meta }) {
            return [true, ''];
        }
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Responsible Engineer",
        "field": "ResponsibleEngineer"
    }, {
        "headerName": "Mod Type",
        "field": "Type"
    }],
    "rowActions": [{
        "label": "Open Design Change",
        action({ router, rowItem }) {
            router.push(`/Database/DesignChanges/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Design Change",
        "action": editDesignChange
    }, {
        "label": "Import Job Cards to Commercial",
        "action": importDesignChangesJobCards,
        show({ meta }) {
            return [meta.ParentType === "Commercials", "Can only perform this opperation within a commercial."]
        }
    }, {
        "label": "Unlink this Design Change",
        "action": unlinkDesignChange,
        show({ meta, pageItem, rowItem }) {
            if(pageItem.Parent === rowItem.Number) return [false, "Cannot unlink this if the design change is the main parent."]
            return [!!meta.ParentType, "Can only perform this opperation."]
        }
    }]
};
