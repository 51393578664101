import { appConfig, makeDropdown, pad } from "../../../utils";

import {
    revisionQuality
} from "./documentUtils";

const fields = ({ pageItem, meta }) => {
    return [{
        "label": "Title",
        "name": "Title",
        "type": "text",
        "divClass": "md:col-span-3"
    }, {
        "label": "Is Prototype?",
        "name": "Prototype",
        "type": "dropdown",
        "dropdownData": [{
            "label": "Yes",
            "id": "Yes"
        }, {
            "label": "No",
            "id": "No"
        }],
        show({ liveInputData }) {
            return liveInputData.Type === "ACC";
        }
    }, {
        "label": "Sub Document Type",
        "name": "SubDocumentType",
        "type": "dropdown",
        "labelKey": "Name",
        "idKey": "Name",
        "dataLocation": "Database/SubDocumentTypes",
        show({ liveInputData }) {
            return liveInputData.Type === "TREP";
        }
    }, {
        "label": "Manufacturer",
        "name": "Manufacturer",
        "type": "dropdown",
        "labelKey": "Name",
        "idKey": "Name",
        "dataLocation": "Database/Manufacturers",
        show({ liveInputData }) {
            return ["EAF", "EADT"].includes(liveInputData.Type);
        }
    }, {
        "label": "Does this have a MPDS Supplement attached?",
        "name": "MPDS",
        "type": "dropdown",
        "dropdownData": [{
            "label": "Yes",
            "id": true
        }, {
            "label": "No",
            "id": false
        }],
        show({ liveInputData }) {
            return ['TEC', 'AMMS'].includes(liveInputData.Type);
        }
    }];
};

export const addDocument = ({ form, pageItem, meta, store }) => {
    form.setItem(pageItem);

    let title = pageItem?.Title.includes(" - ") ? pageItem.Title.split(" - ")[1] : pageItem.Title;
    // if the to has subDocType then we need to update the title
    
    if (pageItem.SubDocumentType) {
        title = `${pageItem.SubDocumentType} - ${title}`;
    }

    form.setValues({
        "Title": title
    });

    form.setConfig({
        "title": "Add Document",
        "type": "push",
        "location": "Database/Documents",
        "fields": [{
            "label": "Parent",
            "type": "value",
            value() {
                return pageItem.Number
            }
        }, {
            "label": "New Document Number",
            "type": "value",
            value({ liveInputData, dropdownData }) {
                if (!liveInputData.Type) return "Select document type"
                const selectedDocumentType = dropdownData.Type.find(documentType => {
                    return documentType.id === liveInputData.Type
                });
                return `${appConfig.docPrefix}${selectedDocumentType.meta.NameShort}-${pad(selectedDocumentType.meta.Count)}`
            }
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "labelKey": "NameShort",
            "idKey": "NameShort",
            "dataLocation": "Database/DocumentTypes",
            dataFilter(documentType) {
                return documentType[meta.ParentType] === true || documentType[meta.ParentType] === "true";
            }
        }, ...fields({ form, pageItem, meta, store })],
        preSubmitHook({ liveInputData, dropdownData }) {
            const selectedDocumentType = dropdownData.Type.find(documentType => {
                return documentType.id === liveInputData.Type
            });

            const startingRevision = selectedDocumentType.meta.NameShort === "FAC" ? "NONE" : liveInputData.Prototype === "Yes" ? "P1" : "1";

            return {
                "Cancelled": false,
                "CreatedBy": store.getters.userName,
                "ExpectedTime": selectedDocumentType.meta.ExpectedTime,
                "Date": {
                    "Created": new Date().toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A"
                },
                "SignedOff": false,
                "Links": {
                    [pageItem.ID]: "---R1"
                },
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                [meta.ParentType]: {
                    [pageItem.ID]: true
                },
                "Revision": startingRevision,
                "RevisionID": "---R1",
                "Revisions": {
                    "---R1": {
                        "Revision": startingRevision,
                        "Reason": "Document Created",
                        "ID": "---R1",
                        "Date": new Date().toISOString(),
                        "Quality": revisionQuality(meta.ParentType === "Documents")
                    }
                },
                "TypeID": selectedDocumentType.meta.ID,
                "UploadStatus": selectedDocumentType.meta.RequiresUploadStatus ? "Not Uploaded" : "N/A",
                "Number": `${appConfig.docPrefix}${selectedDocumentType.meta.NameShort}-${pad(selectedDocumentType.meta.Count)}`
            }
        },
        async postSubmitHook({ db, data, newData, typeRef, dropdownData, liveInputData }) {
            const selectedDocumentType = dropdownData.Type.find(documentType => {
                return documentType.id === liveInputData.Type
            });
            
            await db.utils.updateRefs(newData, "Database/Refs/Documents/", ["Number", "ID", "Title", "Date", "Revision", "Parent", "ParentID", "SubDocumentType", "MPDS"]);
            await db.set(`Database/SuperRefs/Documents/${newData.ID}`, {
                k: newData.ID,
                c: newData.Number,
            });
            
            await db.set(`Database/${meta.ParentType}/${pageItem.ID}/Documents/${typeRef.key}`, true);
            await db.set(`Database/Documents/${typeRef.key}/${meta.ParentType}/${pageItem.ID}`, true);
            await db.set(`Database/DocumentTypes/${selectedDocumentType.meta.ID}/Count`, selectedDocumentType.meta.Count + 1);
        }
    });
    form.getDropdownData();
    form.toggle();
};
// This function edits a document based on the input fields of a given form
export const editDocument = ({ form, item, meta, pageItem, store }) => {

    // Set the values of the form to the existing document's values
    form.setValues(item);

    // Set configuration options for the form
    form.setConfig({
        "title": "Update Document",
        "type": "update",
        "location": `Database/Documents/${item.ID}`,
        "description": `Editing document: ${item.Number}`,
        "fields": fields({ form, pageItem, meta, store }),
        
        // Async hook that uploads files associated with the edited document
        async postSubmitHook({ data, db, typeRef, files }) {
            let d = {
                ...item,
                ...data
            };
            await db.utils.updateRefs(d, "Database/Refs/Documents/", ["Number", "ID", "Title", "Date", "Revision", "Parent", "ParentID", "SubDocumentType", "MPDS"]);
            await db.set(`Database/SuperRefs/Documents/${item.ID}`, {
                k: item.ID,
                c: item.Number,
            });

            if (!files || files.length < 1) return;
            for (const file of files) {

                // Upload file to specific directory in database
                await db.upload(`Database/Documents/${item.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });

    // Get data for any dropdown components within the form
    form.getDropdownData();

    // Toggle the visibility of the form
    form.toggle();
};

export const cancelDocument = ({ form, item }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Cancel Document",
        "description": `Are you sure you want to cancel document ${item.Number}?`,
        "submitButtonLabel": "Yes, Cancel Document",
        "submitButtonClass": "cancel",
        "type": "update",
        "location": `Database/Documents/${item.ID}`,
        preSubmitHook() {
            return {
                "Cancelled": true
            }
        },
        async postSubmitHook({ db, item }) {
            await db.set(`Database/Refs/Documents/${item.ID}/Cancelled`, true);
        }
    });
    form.toggle();
};

export const checkDocument = type => ({ form, item, store, db }) => {

    let currentRevision = item.Revisions[item.RevisionID];

    form.setValues(item);
    form.setConfig({
        "title": "Signoff Document",
        "type": "update",
        "description": currentRevision.Quality[type].RolledBack ?
            "This check has been rolled back in the past. Only enter time for work completed since the last roll back." :
            "",
        "descriptionStyles": "bg-orange rounded font-bold m-2",
        "location": `Database/Documents/${item.ID}`,
        "submitButtonLabel": "I confirm",
        "fields": [{
            "label": `I confirm this document complies with the relevant standards outlined in the <a class="font-bold text-primary cursor-pointer" href="${store.state.handbook}" target="_blank">Current Procedure (click to open)</a>.`,
            "name": "ProcedureConfirmation",
            "type": "checkbox",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                [type]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/By`]: store.getters.userName,
                [`Revisions/${item.RevisionID}/Quality/${type}/Completed`]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/Date`]: (new Date()).toISOString()
            };
        }
    });
    form.toggle();
};

export const uncheckDocument = type => ({ form, item, store, db }) => {
    form.setValues(item);
    form.setConfig({
        "title": `Cancel ${type} Check`,
        "type": "update",
        "location": `Database/Documents/${item.ID}`,
        "submitButtonLabel": "I confirm",
        "fields": [],
        preSubmitHook({ data }) {
            return {
                [type]: true,
                [`Revisions/${item.RevisionID}/Quality/${type}/By`]: "N/A",
                [`Revisions/${item.RevisionID}/Quality/${type}/Completed`]: false,
                [`Revisions/${item.RevisionID}/Quality/${type}/Date`]: "N/A",
                [`Revisions/${item.RevisionID}/Quality/${type}/RolledBack`]: true

            };
        },
        postSubmitHook({ data }) {
            db.push(`Database/Documents/${item.ID}/Time`, {
                "UserID": store.getters.userID,
                "Date": (new Date()).toISOString(),
                "Type": type,
                "Time": data._time
            });
        }
    });
    form.toggle();
};

export const reviseDocument = ({ form, pageItem, rowItem, store }) => {

    // Calculate the next revision
    let nextRevision = rowItem.Revision.toString().includes("P") ? `P${parseInt(rowItem.Revision.split("P")[1]) + 1}` : parseInt(rowItem.Revision) + 1;
    let nextRevisionID = `--R${nextRevision}`;
    form.setItem(rowItem);
    form.setConfig({
        "title": "Revise Document",
        "description": `Revise Document <strong class="text-primary">${rowItem.Number}</strong> from Revision <strong class="text-primary">${rowItem.Revision}</strong> to Revision <strong class="text-primary">${nextRevision}</strong>. This action will revise the document and only link the new revision to the current item.<br />Make sure all time has been recorded for this revision.`,
        "location": `Database/Documents/${rowItem.ID}`,
        "type": "update",
        "fields": [{
            "label": "Reason",
            "name": `Revisions[${nextRevisionID}][Reason]`,
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/DocumentRevisionTypes",
            "divClass": "cols-span-3"
        }],
        preSubmitHook({ data }) {
            return {
                "Revision": nextRevision,
                "RevisionID": nextRevisionID,
                "Signedoff": false,
                "Completed": false,
                "Revisions": {
                    [nextRevisionID]: {
                        "Date": (new Date()).toISOString(),
                        "Revision": nextRevision,
                        "RevisedBy": store.state.user.Basic.name,
                        "ID": nextRevisionID,
                        "Quality": revisionQuality(rowItem.ParentType === "Documents")
                    }
                },
                "Links": {
                    [pageItem.ID]: nextRevisionID
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const revisionRollback = ({ form, item, db, meta, toast }) => {

    //order the revisions by date from oldest to newest
    let revisions = Object.values(item.Revisions).sort((a, b) => {
        return new Date(a.Date) - new Date(b.Date);
    });

    let previousRevision = revisions[revisions.length - 2];

    if (!previousRevision) {
        toast.warn("No previous revision found");
        return;
    }

    // check the links and roll back any links
    let links = Object.fromEntries(
        Object
            .entries(item.Links)
            .filter(([key, value]) => {
                return value === item.RevisionID;
            }).map(([key, value]) => {
                return [`Links/${key}`, previousRevision.ID];
            })
    );

    form.setConfig({
        "title": "Rollback Revision",
        "description": `Rollback revision from ${item.Revision} to ${previousRevision.Revision}.`,
        "type": "update",
        "location": `Database/Documents/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                [`Revisions/${item.RevisionID}`]: null,
                "RevisionID": previousRevision.ID,
                "Revision": previousRevision.Revision,
                ...links
            }
        }
    });
    form.toggle();
};

export const linkDocument = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": "Link Document",
        "description": `Link a Document to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Documents`,
        "fields": [{
            "label": "Document",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Refs/Documents",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data }) {
            return {
                [data._doc]: true,
                "_doc": null,
                [meta.ParentType]: {
                    [pageItem.ID]: true
                }
            };
        },
        postSubmitHook({ liveInputData }) {
            db.set(`Database/Documents/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const download = async ({ item, db, toast }) => {
    try {

        let url = await db.download(`Database/Documents/${item.ID}/${item.Number.replace(".", "_")}_${item.Revision}.docx`);
        window.open(url, "_blank");
    } catch (err) {
        toast.error("Document not found")
    }
};

export const upload = async ({ item, db, toast }) => {
    try {

        let url = await db.download(`Database/Documents/${item.ID}/${item.Number.replace(".", "_")}_${item.Revision}.docx`);
        window.open(url, "_blank");
    } catch (err) {
        toast.error("Document not found")
    }
};

export const switchParent = async ({ form, item, store, attachedItems }) => {
    form.setConfig({
        "title": "Change Parent",
        "type": "update",
        "location": `Database/Documents/${item.ID}/`,
        "fields": [{
            "label": "New Parent",
            "type": "dropdown",
            "name": "ParentID",
            "dropdownData": makeDropdown(store, attachedItems[item.ParentType].items, "Number", "ID")
        }],
        preSubmitHook({ selectedDropdowns }) {
            return {
                "Parent": selectedDropdowns.ParentID.label,
                [`Links/${selectedDropdowns.ParentID.id}`]: item.RevisionID
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const makePrototype = async ({ form, rowItem, pageItem, store, meta }) => {
    form.setConfig({
        "title": "Make this Document a Prototype",
        "type": "update",
        "location": `Database/Documents/${rowItem.ID}/`,
        "fields": [],
        preSubmitHook({ data }) {
            return {
                "Revision": "P1",
                "RevisionID": "---P1",
                "Signedoff": false,
                "Revisions": {
                    "---P1": {
                        "Date": (new Date()).toISOString(),
                        "Revision": "P1",
                        "RevisedBy": store.state.user.Basic.name,
                        "Reason": "Set to Prototype",
                        "ID": "---P1",
                        "Quality": revisionQuality(meta.ParentType === "Documents")
                    }
                },
                "Links": {
                    [pageItem.ID]: "---P1"
                }
            }
        }
    });

    form.toggle();
};

export const reviseDrawingToA = ({ form, rowItem, pageItem, store, meta }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Revise Prototype to Revision 1",
        "type": "update",
        "location": `Database/Documents/${rowItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Revision": "1",
                "RevisionID": "---R1",
                "Revisions/---R1": {
                    "Date": new Date().toISOString(),
                    "ID": "---R1",
                    "Quality": revisionQuality(meta.ParentType === "Documents"),
                    "RevisedBy": store.state.user.Basic.name,
                    "Reason": "From Prototype to Rev 1",
                    "Revision": "1"
                },
                [`Links/${pageItem.ID || rowItem.ParentID}`]: "---R1"
            }
        }
    });
    form.toggle()
};

export const unlinkDocument = ({ form, pageItem, rowItem, meta, db }) => {
    form.setConfig({
        "title": "Link a Document",
        "description": `Unlink a document to ${pageItem.Number}`,
        "type": "delete",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Documents/${rowItem.ID}`,
        "fields": [],
        postSubmitHook() {
            db.delete(`Database/Documents/${rowItem.ID}/${meta.ParentType}/${pageItem.ID}`);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const downloadBlankForm = async ({ rowItem, db, store }) => {
    const docType = await db.once(`Database/DocumentTypes/${rowItem.TypeID}`);
    let file = await db.download(`Database/DocumentTypes/${docType.ID}/Revisions/${docType.RevisionID}/File`);
    window.open(file, "__blank");
};

export const linkMiscellaneous = async ({ pageItem, db, form }) => {

};

export const changeLinkedRevision = ({ form, pageItem, rowItem, meta }) => {
    form.setConfig({
        "title": "Change linked revision",
        "type": "update",
        "location": `Database/Documents/${rowItem.ID}/Links/`,
        "fields": [{
            "label": "Revision",
            "name": pageItem.ID,
            "type": "dropdown",
            dropdownData: Object.entries(rowItem.Revisions).map(([key, value]) => ({ id: key, label: value.Revision }))
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const updateUploadedStatus = async ({ form, item }) => {
    form.setValue({
        "UploadStatus": item.UploadStatus || "Not Uploaded"
    });

    form.setConfig({
        "title": "Update Upload Status",
        "type": "update",
        "location": `Database/Documents/${item.ID}`,
        "fields": [{
            "label": "Upload Status",
            "name": "UploadStatus",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Database/UploadStatuses",

        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const createBasicDocuments = async ({ store, db, parent, parentType }) => {
    const documentTypes = await db.store("Database/DocumentTypes", store);

    let newDocuments = []

    for (const type of Object.values(documentTypes)) {
        if (!type.Standard || !type.Standard[parentType]) {
            continue;
        };

        newDocuments.push({
            "Number": `${appConfig.docPrefix}${type.NameShort}-${pad(parseInt(type.Count))}`,
            "Title": parent.Title,
            "Date": {
                "Created": new Date().toISOString(),
                "Cancelled": "N/A",
                "Signedoff": "N/A"
            },
            "Cancelled": false,
            "Count": parseInt(type.Count),
            "Signedoff": false,
            "Parent": parent.Number,
            "ParentID": parent.ID,
            "ParentType": parentType,
            [parentType]: {
                [parent.ID]: true
            },
            "Type": type.NameShort,
            "TypeID": type.ID,
            "MPDS": false,
            "Revision": 1,
            "RevisionID": `--R1`,
            "Revisions": {
                "--R1": {
                    "Revision": 1,
                    "Reason": "Document Created",
                    "ID": "--R1",
                    "Date": new Date().toISOString(),
                    "Quality": {
                        "Approver": {
                            "By": "N/A",
                            "Completed": false,
                            "Date": "N/A"
                        },
                        "Checker": {
                            "By": "N/A",
                            "Completed": false,
                            "Date": "N/A"
                        },
                        "Compiler": {
                            "By": "N/A",
                            "Completed": false,
                            "Date": "N/A"
                        }
                    }
                }
            },
            "Links": {
                [parent.ID]: "--R1"
            }
        });
    }

    return newDocuments;
};
