let apps = [{
    "id": "Home",
    "label": "Home",
    "children": ["Home", "User Manager"].map(i => ({ label: i, id: i }))
}, {
    "id": "Database",
    "label": "Database",
    "children": ["Commercials", "Design Changes", "Flight Conditions", "Repairs", "Documents", "Drawings", "DQNs", "Concessions", "DOIs", "Service Bulletins", "SADDs", "EWIS", "Arrangements", "Job Cards", "Job Card Findings", "Occurrences", "MORs", "Effectivities", "Purchase Orders", "Variation Orders", "EAF Part Numbers", "Settings"].map(i => ({ label: i, id: i }))
}, {
    "id": "Audits",
    "label": "Audits",
    "children": ["Reports", "Non Conformities", "Procedures", "Noncon Performance"].map(i => ({ label: i, id: i }))
}, {
    "id": "Claims",
    "label": "Claims",
    "children": ["My Claims", "Authorisation", "Payments", "Completed"].map(i => ({ label: i, id: i }))
}, {
    "id": "Leave",
    "label": "Leave",
    "children": ["Planner", "My Summary", "Requests", "Staff"].map(i => ({ label: i, id: i }))
}, {
    "id": "People",
    "label": "People",
    "children": ["Forms", "Settings", "Signatory Matrix"].map(i => ({ label: i, id: i }))
}];

let status = ["Under Review", "No Fix Required", "Fix Started", "Fix Live"].map(i => ({ label: i, id: i }));

export const addBug = ({ form, store }) => {
    form.setConfig({
        "title": "Add New Bug",
        "type": "push",
        "location": "Home/Bugs",
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "App / Section",
            "name": "Section",
            "type": "dropdown",
            "dropdownData": apps,
            "disableBranchNode": true
        },
        {
            "label": "Description",
            "name": "Description",
            "type": "richtext",
            "divClass": "col-span-3"
        }],
        preSubmitHook() {
            return {
                "User": store.getters.userObject,
                "Status": "Under Review",
                "Date": {
                    "Created": (new Date()).toISOString()
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editBug = ({ form, pageItem }) => {
    form.setValues(pageItem)
    form.setConfig({
        "title": "Update Bug",
        "type": "update",
        "location": `Home/Bugs/${pageItem.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text"
        }, {
            "label": "App / Section",
            "name": "Section",
            "type": "dropdown",
            "dropdownData": apps,
            "disableBranchNode": true
        },
        {
            "label": "Status",
            "name": "Status",
            "type": "dropdown",
            "dropdownData": status
        },
        {
            "label": "Description",
            "name": "Description",
            "type": "richtext",
            "divClass": "col-span-3"
        }],
        preSubmitHook() {
            return {
                "Date": {
                    "Updated": (new Date()).toISOString()
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};


export const completeBug = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Complete Bug",
        "type":  "delete",
        "location": `Home/Bugs/${pageItem.ID}`,
        "fields": []
    });
    form.toggle();
};
