import {
    appConfig,
    next,
    pad
} from "../../../utils";

export const addArrangement = ({ form, db }) => {
    form.setConfig({
        "title": "Add Arrangement",
        "type": "push",
        "location": "Database/Arrangements",
        "fields": [{
            "label": "Number",
            "type": "value",
            value({ dropdownData, liveInputData }) {
                if (liveInputData.TypeID) {
                    const selectedType = dropdownData.TypeID.find(i => i.id === liveInputData.TypeID);
                    return `${appConfig.docPrefix}${selectedType.meta.NameShort}-${pad(selectedType.meta.Count)}`
                } else {
                    return "Select Type"
                }
            },
            "divClass": "col-span-3"
        },
        {
            "label": "Type",
            "name": "TypeID",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "ID",
            "dataLocation": "Database/ArrangementTypes"
        }, {
            "label": "Vendor / Arrangement With (external)",
            "name": "With",
            "type": "text",
            show({ liveInputData, dropdownData }) {
                const selectedType = dropdownData.TypeID.find(i => i.id === liveInputData.TypeID);
                if (!selectedType) return true;
                else if (selectedType.meta.NameShort === "IND") return false;
                else return !["DO", "TH"].includes(selectedType.meta.NameShort);
            }
        }, {
            "label": "Vendor / Arrangement With (external)",
            "name": "With",
            "type": "dropdown",
            "labelKey": "DropdownLabel",
            "idKey": "Name",
            "dataLocation": "Database/Vendors",
            show({ liveInputData, dropdownData }) {
                const selectedType = dropdownData.TypeID.find(i => i.id === liveInputData.TypeID);
                if (!selectedType) return false;
                else if (selectedType.meta.NameShort === "IND") return false;
                else return ["DO", "TH"].includes(selectedType.meta.NameShort);
            },
            map(i) {
                return {
                    ...i,
                    "DropdownLabel": `${i.Name} - ${i.Number}`
                }
            }
        }, {
            "label": "Vendor / Arrangement With (external)",
            "name": "Signatory",
            "type": "dropdown",
            "labelKey": "Name",
            "idKey": "Name",
            "hasOther": true,
            "dataLocation": "Matrices/SignatoryMatrix/Signatories",
            map(i) {
                return {
                    ...i,
                    "Name": `${i.FirstName} ${i.LastName}`
                }
            },
            show({ liveInputData, dropdownData }) {
                const selectedType = dropdownData.TypeID.find(i => i.id === liveInputData.TypeID);
                if (!selectedType) return false;
                else if (selectedType.meta.NameShort === "IND") return true;
            }
        }, {
            "label": "Arrangement Requested (internal)",
            "name": "Requested",
            "type": "text",
            "labelKey": "Name"
        }, {
            "label": "Scope",
            "name": "Scope",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Notes",
            "name": "Notes",
            "type": "text",
            "divClass": "col-span-3"
        }],
        preSubmitHook({ dropdownData, liveInputData, data }) {
            const selectedType = dropdownData?.TypeID?.find(i => i.id === liveInputData.TypeID);
            const selectedVendor = dropdownData?.VendorID?.find(i => i.id === liveInputData.VendorID);

            return {
                "Number": `${appConfig.docPrefix}${selectedType.meta.NameShort}-${pad(selectedType.meta.Count)}`,
                "Type": selectedType.meta.NameShort,
                "Cancelled": false,
                "Completed": false,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Review": (new Date()).toISOString(),
                    "Cancelled": "N/A",
                    "Completed": "N/A"
                },
                "Status": "Pending",
                "Revision": 1,
                "RevisionID": "---r1",
                "Vendors": selectedVendor ? {
                    [selectedVendor.id]: true
                } : {},
                "Revisions": {
                    "---r1": {
                        "Revision": 1,
                        "ID": "---r1",
                        "Date": (new Date()).toISOString()
                    }
                },
                "With": selectedVendor?.label || data.Signatory || data.With
            }
        },
        postSubmitHook({ typeRef, data }) {
            db.set(`Database/Vendors/${data.VendorID}/Arrangements/${typeRef.key}`, true);
            db.utils.upCount(`Database/ArrangementTypes/${data.TypeID}/Count`);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editArrangement = ({ form, pageItem }) => {
    form.setValues(pageItem);
    form.setConfig({
        "title": "Edit Arrangement",
        "type": "update",
        "location": `Database/Arrangements/${pageItem.ID}`,
        "fields": [{
            "label": "Vendor / Arrangement With (external)",
            "name": "With",
            "type": "text"
        }, {
            "label": "Arrangement Requested (internal)",
            "name": "Requested",
            "type": "text",
            "labelKey": "Name"
        }, {
            "label": "Scope",
            "name": "Scope",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Notes",
            "name": "Notes",
            "type": "text",
            "divClass": "col-span-3"
        }]
    });
    form.getDropdownData();
    form.toggle();
};

export const editArrangementStatus = ({ form, pageItem }) => {
    form.setValues({
        "Date[Review]": pageItem.Date.Review,
        "Status": pageItem.Status
    });
    form.setConfig({
        "title": "Review Date ",
        "type": "update",
        "location": `Database/Arrangements/${pageItem.ID}`,
        "fields": [{
            "label": "Review Date",
            "type": "date",
            "name": "Date[Review]"
        },
        {
            "label": "Status",
            "name": "Status",
            "type": "dropdown",
            "dropdownData": [{
                "id": "Pending",
                "label": "Pending"
            }, {
                "id": "Approved",
                "label": "Approved"
                }, {
                    "id": "Inactive",
                    "label": "Inactive"
                }]
        }
        ]
    });
    form.getDropdownData();
    form.toggle();
};

export const reviseArrangement = ({ form, pageItem, store }) => {
    form.setItem(pageItem);
    form.setConfig({
        "title": "Revise Arrangement",
        "description": `Revise <strong>${pageItem.Number}</strong> from <strong>${pageItem.Revision}</strong> to <strong>${next(pageItem.Revision)}</strong>`,
        "submitButtonLabel": "Revise",
        "type": "update",
        "location": `Database/Arrangements/${pageItem.ID}`,
        "fields": [{
            "label": "Next Revision",
            "type": "value",
            value() {
                return next(pageItem.Revision)
            }
        }, {
            "label": "Reason",
            "type": "text",
            "name": `Revisions[---r${next(pageItem.Revision)}][Reason]`
        }],
        preSubmitHook() {
            return {
                "Revision": next(pageItem.Revision),
                "RevisionID": `---r${next(pageItem.Revision)}`,
                "Revisions": {
                    ...pageItem.Revisions,
                    [`---r${next(pageItem.Revision)}`]: {
                        "Revision": next(pageItem.Revision),
                        "ID": `---r${next(pageItem.Revision)}`,
                        "RevisedBy": store.state.user.Basic.name,
                        "Date": (new Date()).toISOString()
                    }
                }
            }
        }
    });
    form.toggle();
};

export const addFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": `Database/Arrangements/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "File": files[0][0].name
            };
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`Database/Arrangements/${pageItem.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const removeFile = ({ form, rowItem, pageItem }) => {
    form.setConfig({
        "title": "Remove File",
        "submitButtonLabel": "Remove",
        "submitButtonClass": "cancel",
        "type": "delete",
        "location": `Database/Arrangements/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.remove(`Database/Arrangements/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.File);
            }
        }
    });
    form.toggle();
};

export const linkArrangement = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": "Link Arrangement",
        "description": `Link a Arrangement to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Arrangements`,
        "fields": [{
            "label": "Arrangement",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Arrangements",
            "labelKey": "Number",
            "idKey": "ID",
            "search": true
        }],
        preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            }
        },
        postSubmitHook({ liveInputData }) {
            db.set(`Database/Arrangements/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addVendor = ({ form, store }) => {
    form.setConfig({
        "title": "Add Vendor Audit",
        "location": "Database/Vendors",
        "type": "push",
        "fields": [{
            "label": "Name",
            "label": "Name",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}VEND-${pad(store.state.counts.database.vendors.count)}`
            }
        }, {
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Notes / Limitations",
            "name": "Notes",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }],
        preSubmitHook() {
            return {
                "Number": `${appConfig.docPrefix}VEND-${pad(store.state.counts.database.vendors.count)}`,
                "Date": {
                    "Created": (new Date()).toDateString()
                }
            };
        }
    });
    form.toggle();
};

export const editVendor = ({ form, item }) => {
    form.setValues({
        "Name": item.Name
    });

    form.setConfig({
        "title": "Edit Vendor Audit",
        "location": `Database/Vendors/${item.ID}`,
        "type": "update",
        "fields": [{
            "label": "Name",
            "name": "Name",
            "type": "text"
        }, {
            "label": "Notes / Limitations",
            "name": "Notes",
            "type": "richtext",
            "divClass": "md:col-span-3"
        }]
    });
    form.toggle();
};

export const unlinkVendor = ({ form, pageItem, rowItem, db, meta }) => {
    form.setConfig({
        "title": "Remove Linked Vendor",
        "description": `Remove Link a Vendor to ${pageItem.Number}`,
        "type": "delete",
        "location": `Database/Arrangements/${pageItem.ID}/Vendors/${rowItem.ID}`,
        "fields": []
    });

    form.toggle();
};

export const linkVendor = ({ form, pageItem, db, meta }) => {
    form.setConfig({
        "title": "Link Vendor",
        "description": `Link a Vendor to ${pageItem.Number}`,
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/Vendors`,
        "fields": [{
            "label": "Vendor Audtis",
            "name": "_doc",
            "type": "dropdown",
            "dataLocation": "Database/Vendors",
            "labelKey": "Number",
            "idKey": "ID"
        }],
        preSubmitHook({ data }) {
            return {
                "_doc": null,
                [data._doc]: true
            }
        },
        postSubmitHook({ liveInputData }) {
            db.set(`Database/Vendors/${liveInputData._doc}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const addVendorFile = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Add File",
        "submitButtonLabel": "Add",
        "type": "push",
        "location": `Database/Vendors/${pageItem.ID}/Files`,
        "fields": [{
            "label": "File",
            "name": "File",
            "type": "file"
        }],
        preSubmitHook({ files }) {
            return {
                "Date": (new Date()).toISOString(),
                "File": files[0][0].name
            };
        },
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.upload(`Database/Vendors/${pageItem.ID}/Files/${typeRef.key}`, file[0]);
            }
        }
    });
    form.toggle();
};

export const removeVendorFile = ({ form, rowItem, pageItem }) => {
    form.setConfig({
        "title": "Remove File",
        "submitButtonLabel": "Remove",
        "submitButtonClass": "cancel",
        "type": "delete",
        "location": `Database/Vendors/${pageItem.ID}/Files/${rowItem.ID}`,
        "fields": [],
        async postSubmitHook({ db, typeRef, files }) {
            for (const file of files) {
                await db.remove(`Database/Vendors/${pageItem.ID}/Files/${rowItem.ID}`, rowItem.File);
            }
        }
    });
    form.toggle();
};
