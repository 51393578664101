<template>
  <Shell :label="label">
    <template v-slot:comp>
      <div :class="`w-full text-sm leading-3 ${
          grow ? '' : 'whitespace-nowrap truncate'
        }`" :style="`line-height: 36px; ${
          !grow
            ? 'height: 36px'
            : 'height: 100%; max-height: 600px; overflow-y: auto'
        }; background: rgba(0, 0, 0, 0); outline: none;`" :aria-label="value" tabindex="0" role="heading">
        <trmplte v-html="value"></trmplte>
      </div>
      <!-- <Richtext
        v-else
        :value="value"
        :style="`line-height: 36px; ${!grow
            ? 'height: 36px'
            : 'height: 100%; max-height: 600px; overflow-y: auto'
          }; background: rgba(0, 0, 0, 0); outline: none;`"
        readonly /> -->
      <input type="hidden" :name="name" :value="value" v-if="name" />
      <font-awesome-icon class="text-primary mr-2 cursor-pointer" icon="eye" @click="goto(link)"
        v-show="!!link && !!value"></font-awesome-icon>
      <div v-if="!!customIcon">
        <font-awesome-icon class="text-primary mr-2 cursor-pointer" :icon="customIcon"
          @click="$emit('customIconClicked', value)" v-show="!!customIcon"></font-awesome-icon>
      </div>
    </template>
  </Shell>
</template>
<script setup="props">
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import { IconButton } from "../index.js";
import Richtext from "./Richtext.vue";

import Shell from "./Shell.vue";
const router = useRouter();
// define the component props
defineProps({
  link: String,
  label: String,
  value: [String, Number],
  grow: Boolean,
  name: String,
  customIcon: String,
  richtext: Boolean,
});

const goto = (address) => {
  router.push(address);
};

defineEmits(["customIconClicked"]);

// get the attrb on the component and bind them to the input element
// const attrs = useAttrs();
</script>
