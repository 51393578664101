import { editVendor, linkVendor, unlinkVendor } from "./actions";

export const vendorAudits = {
    "label": "Vendor Audits",
    "key": "Vendors",
    "location": "Database/Vendors/",
    "actions": [{
        "label": "Link Vendor",
        "action": linkVendor,
        show({ meta }) {
            return [true, ''];
        }
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Name",
        "field": "Name"
    }],
    "rowActions": [{
        "label": "Open Vendor",
        action({ router, rowItem }) {
            router.push(`/Database/Vendors/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Vendor",
        "action": editVendor
    }, {
        "label": "Remove Linked Vendor",
        "action": unlinkVendor
    }],
    show({ pageItem }) {
        return ["--a11", "--a09", "--a04"].includes(pageItem.TypeID)
    }
}
