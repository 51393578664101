import {
    addManufacturer,
    editManufacturer
} from "./actions";

export default {
    "label": "Manufacturers",
    "key": "Manufacturers",
    "location": "Database/Manufacturers/",
    "headers": [{
        "headerName": "Name",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Manufacturer",
        "action": addManufacturer
    }],
    "rowActions": [{
        "label": "Edit Manufacturer",
        "action": editManufacturer
    }]
}
