import { addPartNumber, editPartNumber } from "./actions"

export default {
    "label": "Part Numbers",
    "key": "PartNumbers",
    "location": "Database/PartNumbers/",
    "actions": [{
        "label": "Add Part Number",
        "action": addPartNumber
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }],
    "rowActions": [{
        "label": "Open Part Number",
        action({ router, rowItem }) {
            router.push(`/Database/PartNumbers/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Part Number",
        "action": editPartNumber
    }],
    show({ pageItem }) {
        return ["EAR", "EAF", "EADT"].includes(pageItem.Type)
    }
}
