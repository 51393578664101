import {
    BooleanFilter,
    BooleanValue
} from "@comp/index.js";

import {
    dateCollumn
} from "../../views/standardDatatable";

export const getItemLocations = (pageItem, tables) => {
    return Object.values(tables).reduce((acc, section) => {
        let sectionLocations = [];
        // for each section get all the attached items
        section.attachables
            .filter((attachable) => !attachable.field)
            .filter((attachable) => !attachable.local)
            .forEach(attachable => {
                getAttachableLocations(pageItem, attachable).forEach(loc => {
                    sectionLocations.push(loc);
                });
            });
        return [...acc, ...sectionLocations];
    }, []);
};

const getAttachableLocations = (pageItem, attachable) => {
    return Object.keys(pageItem[attachable.key] || {}).map(key => `${attachable.location}${key}`);
};

export const buildHeadersWarningsAndItems = (pageItem, tables, contextData) => {
    try {

        // Initialise the return variable
        const headersWarningsAndItems = {};
        
        // First get the table sections, these are the gray boxes that container the names of the attachable items
        const tableSections = Object.keys(tables);
        
        // For Each section, compute the attachable tables and items
        tableSections.forEach(section => {
            headersWarningsAndItems[section] = computeSectionAttachables(pageItem, tables[section].attachables, contextData);
        });
        
        return headersWarningsAndItems;
    } catch (e) {
        console.error(e);
    }
}

const computeSectionAttachables = (pageItem, arrayOfAttachables, contextData) => {
    return arrayOfAttachables?.map(attachable => {
        const items = buildItems(pageItem, attachable, contextData);
        return {
            ...attachable,
            actions: attachable.actions || [],
            headers: attachable.headers ? buildHeaders(attachable.headers, contextData) : [],
            show: computeShow(attachable, {
                items,
                ...contextData
            }),
            warn: computeWarn(attachable, {
                items,
                ...contextData
            }),
            items,
            attachable: attachable
        }
    });
};

const buildHeaders = (headers, contextData) => {
    return headers.map((_attachedItemToGetHeader) => {
        let attachedItemToGetHeader = {
            ..._attachedItemToGetHeader
        };
        
        if (attachedItemToGetHeader.fieldGetter) {
            attachedItemToGetHeader.field = attachedItemToGetHeader.fieldGetter(contextData);
            delete attachedItemToGetHeader.fieldGetter;
        }
        // Boolean values, add the boolean val formatter and filter
        if (attachedItemToGetHeader.type === "bool") {
            attachedItemToGetHeader.filter = BooleanFilter;
            attachedItemToGetHeader.cellRenderer = BooleanValue;
        }
        if (attachedItemToGetHeader.type === "date") {
            // colOptions.cellRenderer = dateCollumn.cellRenderer;
            attachedItemToGetHeader.comparator = dateCollumn.comparator;
            attachedItemToGetHeader.valueFormatter = dateCollumn.valueFormatter;
        }
        return attachedItemToGetHeader;
    });
}

const buildItems = (pageItem, attachable, contextData) => {

    const itemLocations = getAttachableLocations(pageItem, attachable).filter(i => !!i);
    const items =
        attachable.computedData ?
            attachable.computedData(contextData) :
        attachable.local === true
            ? Object.entries(pageItem[attachable.key] || {}).map(([key, value]) => ({ ...value, ID: key }))
            : attachable.computedRows
                    ? attachable.computedRows({ ...contextData, attachable, itemLocations })
                    : itemLocations

    return (attachable.local && attachable.dataFilter) ? items.filter(attachable.dataFilter) : items;
};

const computeShow = (attachable, contextData) => {
    
    if (!!attachable.show) return attachable.show(contextData);
    else return true;
};

const computeWarn = (attachable, contextData) => {
    if (attachable.warn) return attachable.warn(contextData);
    else return [false, ""];
};
