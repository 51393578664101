import { designChanges, records } from "./internalAttachable";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

const dataFields = [{
    "label": "Name",
    "key": "Basic.name"
}, {
    "label": "Email",
    "key": "Basic.email"
}, {
    "label": "Department",
    "key": "Basic.department"
}, {
    "label": "Position",
    "key": "Basic.job_title"
}, {
    "label": "Manager",
    compute({ pageItem, store }) {
        if (!pageItem?.Basic?.ManagerID) return "No manager";
        else return store.state.users[pageItem.Basic.ManagerID].name
    }
}];

const menuActions = [

];

export default [{
    "path": "/Home/StaffTraining",
    "component": { ...ItemListView },
    "name": "Staff Training Record",
    "meta": {
        "app": "Home",
        "sideNav": true,
        "name": "Staff Training Record",
        "storeData": ["Database/Refs/DesignChanges"],
        "sideNav": true,
        "sideNavPosition": 2,
        "sideNavSection": "Staff",
        "appSwitcher": false,
        "tableSettings": "UserManager",
        "dataPath": "users",
        "selectionRoute": "Home/StaffTraining/{key}",
        filter(item) {
            return item.main
        },
        "menuActions": [],
        lock(user) {
            return [user?.Security?.General?.Training !== true, "Cannot view this area"]
        }
    }
}, {
    "path": "/Home/StaffTraining/:id",
    "component": ItemView,
    "name": "Staff Member",
    "meta": {
        "app": "Home",
        "name": "Staff Member",
        "dataPathOverride": "Userdata/:id/Training",
        "storeData": ["Database/Refs/DesignChanges"],
        title(item) {
            return `Staff: ${item.Basic.name}`;
        },
        itemOverride(item, cd) {
            

            
            const baseData = cd?.store?.state?.users ? cd?.store?.state?.users[cd?.router?.currentRoute?.value?.params?.id] : {};
            
            if(!item) {
                item = {
                    "Records": {}
                };
            };
            
            return {
                "Basic": baseData, 
                "Training": item
            };
        },
        "attachedItems": {
            "Records":{ "attachables":  [records, designChanges]}
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
