<template>
    <!-- Top section splits out the various sections of tables -->
    <div v-for="(tableSection, tableSectionLabel, tableSectionIndex) in tables" :key="tableSectionLabel">
        <!-- Table Section Label -->
        <p class="text-primary font-medium pl-4 my-4 text-xs" v-if="shouldSectionBeHidden(tableSection)">{{ tableSectionLabel }}</p>
        <!-- Gray rounded section that contains all the tables  -->
        <div class="rounded-lg bg-gradient-to-br from-gray-100 to-gray-150 shadow-inner mx-4 my-2 py-2" v-if="shouldSectionBeHidden(tableSection)">
            <div v-for="(tableSelector, tableSelectorIndex) in tableSection" :key="tableSelectorIndex">
                <TableSelectorLink
                    v-if="tableSelector.show"
                    :label="tableSelector.label" 
                    :show="tableSelector.show"
                    :warn="tableSelector.warn[0]"
                    :warnText="tableSelector.warn[1]"
                    :selected="selectedTableIndex === getAdjustedIndex(tableSectionIndex, tableSelectorIndex)" 
                    @change="$emit('selectedtableindexchanged', getAdjustedIndex(tableSectionIndex, tableSelectorIndex))"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import TableSelectorLink from "./TableSelectorLink.vue";

import {
    defineProps,
    onMounted
} from "vue";

defineEmits(["selectedtableindexchanged"]);

let props = defineProps({
    "tables"
        : Object,
    "selectedTableIndex": Number
});

const getAdjustedIndex = (tableSectionIndex, tableSelectorIndex) => ((tableSectionIndex + 1) * 100) + tableSelectorIndex;

const shouldSectionBeHidden = (tableSection) => {
    for (const tableSelector of tableSection) {
        if (tableSelector.show) {
            return true;
        }
    }
    return false;
}

onMounted(() => {
})

</script>
