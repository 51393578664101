import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

import drawingAttachable from "../Drawings/attachable.js";
import documentsAttachable from "../Documents/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import dqnsAttachable from "../DQNs/attachable.js";
import consAttachable from "../Concessions/attachable.js";
import sbAttachable from "../ServiceBulletins/attachable.js";
import {
    thirdPartyDocs,
    thirdPartyDraws,
    thirdPartyOther
} from "../Miscellaneous/attachable.js";


const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Date Issued",
    "key": "Date.Issued",
    "type": "date"
}, {
    "label": "Type",
    "key": "Type"
}, {
    "label": "POA/Arrangement",
    "key": "To"
}, {
    "label": "MDL Revision",
    "key": "MDL.Revision"
}];

const menuActions = [
    // {
    //     "label": "Edit Document",
    //     "action": editDocument
    // },
    // {
    //     "label": "Cancel Document",
    //     "class": "text-red",
    //     "action": cancelDocument
    // }
];

export default [{
    "path": "/Database/SADDs",
    "component": {...ItemListView},
    "name": "SADDs",
    "meta": {
        "app": "Database",
        "name": "SADDs",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "SADDs",
        "dataPath": "Database/SADDs",
        "selectionRoute": "Database/SADDs/{ID}"
    }
}, {
    "path": "/Database/SADDs/:id",
    "component": ItemView,
    "name": "SADD",
    "meta": {
        "app": "Database",
        "name": "SADDs",
        "dataPath": "Database/SADDs",
        "selectionRoute": "Database/SADDs/{ID}",
        title(item) {
            return `SADDs: ${item.Number}`
        },
        "attachedItems": {
            "Attached Items": {
                "attachables": [documentsAttachable, drawingAttachable, effectivitiesAttachable, dqnsAttachable, consAttachable, sbAttachable, thirdPartyDocs,
                    thirdPartyDraws,
                    thirdPartyOther]
            }
        },
        "preventAttachedItemsActions": false,
        "preventAttachedItemsRowActions": false,
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "SADDs"
        }
    }
}];
