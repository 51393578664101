
const dropdownData = [{
    "id": "Design",
    "label": "Design"
}, {
    "id": "Certification",
    "label": "Certification"
}, {
    "id": "Project Management",
    "label": "Project Management"
}, {
    "id": "Design certification",
    "label": "Design certification"
}, {
    "id": "Other",
    "label": "Other"
}];

const fields = [{
    "label": "Date Start",
    "name": "Date[Start]",
    "type": "date"
}, {
    "label": "Date Completed",
    "name": "Date[Complete]",
    "type": "date"
}, {
    "label": "Type",
    "name": "Type",
    "type": "dropdown",
    "dropdownData": dropdownData
}, {
    "type": "text",
    "label": "Approved By",
    "name": "ApprovedBy"
}, {
    "label": "Training / Achievement / Experience",
    "name": "Training",
    "type": "text",
    "divClass": "md:col-span-2"
}, {
    "label": "Outcomes / Comments",
    "name": "Outcomes",
    "type": "text",
    "divClass": "md:col-span-3"
}, {
    "label": "Certificate / Proof",
    "name": "Certificate",
    "type": "file",
    "required": false,
    "divClass": "md:col-span-2"
}];

export const addRecord = ({ form, router, store, db }) => {
    const date = (new Date()).toISOString();
    form.setConfig({
        "title": "Add New Training Record",
        "type": "push",
        "description": `Userdata/${router?.currentRoute?.value?.params.id}/Training/Records`,
        "location": `Userdata/${router?.currentRoute?.value?.params.id}/Training/Records`,
        "fields": fields,
        async postSubmitHook({ files, typeRef }) {
            for (const file of files) {
                if(!file[0]) continue;
                await db.upload(`StaffTrainingRecords/${router?.currentRoute?.value?.params.id}/${typeRef.key}`, file[0]);
                await db.update(`Userdata/${router?.currentRoute?.value?.params.id}/Training/Records/${typeRef.key}`, {
                    "Certificate": file[0].name,
                    "AddedBy": store.getters.userID,
                })
            };
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const removeRecord = ({ form, pageItem, rowItem }) => {
    form.setConfig({
        "title": "Remove Training Record",
        "type": "delete",
        "description": "Are you sure you want to remove this record?",
        "location": `Userdata/${pageItem.Basic.key}/Training/Records/${rowItem.ID}`
    });

    form.toggle();
};

export const updateRecord = ({ form, pageItem, rowItem, store, db, router }) => {
    const date = (new Date()).toISOString();

    form.setValues({
        ...rowItem,
        "Date[Start]": rowItem?.Date?.Start,
        "Date[Complete]": rowItem?.Date?.Complete
    });

    form.setConfig({
        "title": "Revise Handbook",
        "location": `Userdata/${router?.currentRoute?.params.id}/Training/Records/${rowItem.ID}`,
        "type": "update",
        "fields": fields,
       
        async postSubmitHook({ files }) {
            for (const file of files) {
                
                if(!file[0]) continue;
                await db.upload(`StaffTrainingRecords/${router?.currentRoute?.params.id}/${rowItem.ID}`, file[0]);
                await db.update(`Userdata/${router?.currentRoute?.params.id}/Training/Records/${rowItem.ID}`, {
                    "Certificate": file[0].name,
                    "AddedBy": store.getters.userID,
                })
            };
        }
    });
    form.getDropdownData();

    form.toggle();
};

export const downloadCertificate = ({ pageItem, rowItem, db }) => {
    try {

        let rev = rowItem.Revision;
        // let url = await db.download()
        window.open(url, "__blank")
    } catch (err) {
        toast.error("Archive file found");
    }
}

