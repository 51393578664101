import {
    addEffectivity,
    editEffectivity,
    removeEffectivity
} from "./actions";

const actions = [{
    "label": "Add Effectivity",
    "action": addEffectivity,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "DQNs cannot be added here, they must be linked."]
    }
}];

const headers = [{
    "headerName": "Number",
    "field": "Number"
}, {
    "headerName": "Commercial",
    "field": "Commercial"
}];

const rowActions = [{
    "label": "View Effectivity",
    action({ router, rowItem }) {
        router.push(`/Database/Effectivities/${rowItem.ID}`)
    }
}, {
    "break": true,
    "label": "Edit Effectivity",
    "action": editEffectivity
}, {
    "label": "Remove Effectivity",
    "action": removeEffectivity
}];

export default {
    "label": "Effectivities",
    "key": "Effectivities",
    "location": "Database/Effectivities/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        
        return [(!items || items.length < 1), "No effectivities added."];
    }
};
