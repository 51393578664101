<template>
  <div class="m-2 p-2 bg-white rounded flex flex-col" v-if="state.loading">
    <Loading />
  </div>
  <div class="flex flex-col-reverse md:grid md:grid-cols-4" v-show="!state.loading">
    <!-- SELECTOR -->
    <div class="md:col-span-1">
      <AttachableTableSelector :tables="state.tablesAndItems" :selectedTableIndex="selectedTableIndex"
        @selectedtableindexchanged="selectedTableIndexChanged" />
    </div>
    <div class="my-2 col-span-3">
      <div v-for="(
          tableSection, tableSectionLabel, tableSectionIndex
        ) in state.tablesAndItems" :key="tableSectionLabel">
        <div>
          <div v-for="(tableSelector, tableSelectorIndex) in tableSection" :key="tableSelector">
            <div v-show="selectedTableIndex ===
              getAdjustedIndex(tableSectionIndex, tableSelectorIndex)
              ">
              <AttachableTable :ref="(el) =>
                  (state.agTables[tableSelector.id || tableSelector.key] = el)
                " :pageItem="pageItem" :tableConfig="tableSelector" :contextData="contextData" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted,
  reactive,
  defineExpose,
} from "vue";
import { buildHeadersWarningsAndItems } from "./utils";
import AttachableTableSelector from "./AttachableTableSelector.vue";
import AttachableTable from "./AttachableTable.vue";
import { Loading } from "@comp/index";
import { useStore } from "vuex";
// load firebase helper
import { db } from "../../db.js";

const store = useStore();

const state = reactive({
  loading: false,
  tablesAndItems: {},
  agTables: [],
});

const props = defineProps({
  pageItem: Object,
  tables: Object,
  contextData: Object,
  selectedTableIndex: Number,
});

const emit = defineEmits(["selectedtableindexchanged"]);
let retries = 0;
let lastRetryTime = 0;
async function getItems() {

  try {
    if (!props.pageItem) {
      if (retries >= 5) {
        // Maximum number of retries reached
        return;
      }

      // Check if enough time has elapsed since the last retry
      const currentTime = Date.now();
      if (currentTime - lastRetryTime < 250) {
        return;
      }

      retries++;
      lastRetryTime = currentTime;

      // Call the function again after a delay
      setTimeout(() => {
        getItems();
      }, DEBOUNCE_TIME);
      return;
    }

    // Reset retries and lastRetryTime
    retries = 0;
    lastRetryTime = 0;

    // Start the loading icon
    state.loading = true;

    state.tablesAndItems = buildHeadersWarningsAndItems(
      props.pageItem,
      props.tables,
      props.contextData
    );

    // get all the keys of all the items
    const nonComputedItemLocations = Object.values(state.tablesAndItems)
      .flat()
      .filter((i) => !i.computedRows)
      .filter((i) => !i.computedData)
      .filter((i) => !i.local)
      .reduce((acc, i) => {
        return [...acc, ...i.items];
      }, []);

    // now make the calls to get the items we want tto view

    await Promise.all(
      nonComputedItemLocations.map((location) => db.watch(location, store))
    );

    state.tablesAndItems = buildHeadersWarningsAndItems(
      props.pageItem,
      props.tables,
      props.contextData
    );

    
    const computedItemLocations = Object.values(state.tablesAndItems)
      .flat()
      .filter((i) => !!i.computedRows)
      .reduce((acc, i) => {
        return [...acc, ...i.items];
      }, []);

    await Promise.all(
      computedItemLocations.map((location) => db.watch(location, store))
    );
    state.loading = false;
  } catch (err) {
    state.loading = false;
  }
}

const selectedTableIndexChanged = (number) => {
  emit("selectedtableindexchanged", number);
};

const getAttachedItems = () => {
  return Object.values(state?.tablesAndItems || {}).reduce(
    (acc, itemValues) => {
      // Iterate through the items for each table
      itemValues?.forEach((item) => {
        // Add the item to the accumulator object with its key or label as the key
        acc[item.key || item.label] = {
          ...item,
          // Add the corresponding agTable to the item
          agTable: state.agTables[item.key],
        };
      });
      return acc;
    },
    {}
  );
};

const recalculateWarnings = () => {
  Object.entries(state?.tablesAndItems || {}).forEach(
    ([tableSectionLabel, tableSection]) => {
      tableSection?.forEach((table) => {
        const instance = state.agTables[table.key];
        console.log("instance", instance);
        if(!!instance && instance.computeItems) instance.computeItems();
        if (table.attachable.warn) {
          
          table.warn = table.attachable.warn({...props.contextData, items: table.items});
        }
        else {
          table.warn = [false, ""];
        }

      });
    }
  );
};

const getAdjustedIndex = (tableSectionIndex, tableSelectorIndex) =>
  (tableSectionIndex + 1) * 100 + tableSelectorIndex;

onMounted(() => {
  getItems();
});

defineExpose({
  getAttachedItems,
  getItems,
  recalculateWarnings
});
</script>
