import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"

const dataFields = [{
    "label": "Part Number",
    "key": "Number"
}, {
    "label": "Document",
    "key": "Document",
    "link": "Database/Documents/:DocumentID"
}, {
    "label": "Manufacturer",
    "key": "Manufacturer"
}, {
    "label": "Description",
    "key": "Description",
    "class": "lg:col-span-3"
}];

export default [{
    "path": "/Database/PartNumbers",
    "component": {...ItemListView},
    "name": "Part Numbers",
    "meta": {
        "app": "Database",
        "name": "Part Numbers",
        "sideNavSection": "Misc",
        "sideNav": true,
        "icon": "database",
        "tableSettings": "EAFs",
        "dataPath": "Database/PartNumbers",
        "selectionRoute": "Database/PartNumbers/{ID}",
        "menuActions": [],
        filter(item) {
            return item.Cancelled !== true
        }
    }
}, {
    "path": "/Database/PartNumbers/:id",
        "component": ItemView,
    "name": "Part Number",
        "meta": {
            "app": "Database",
            "name": "Part Numbers",
            "dataPath": "Database/PartNumbers",
            "selectionRoute": "Database/PartNumbers/{ID}",
            title(item) {
                return `Commercial: ${item?.Number}`
            },
            "attachedItems": [],
            "dataFields": dataFields
        }
    }];
