import {
    addFileToTester,
    addFormFourFile,
    addNote,
    addPanelLimitations,
    answerQuestion,
    deleteFile,
    downloadFile,
    downloadFile2,
    downloadTesterFile,
    linkFormFourFile,
    reviewAnswer,
    updateAdditionalLimitations,
    updateAdditionalRoles,
    updateNote,
    updatePanel,
    updatePanelLimitations,
    updatePanelLimitations2,
    uploadFile,
    viewNote
} from "./actions";
import {
    addRecord,
    removeRecord,
    updateRecord
} from "./actions";

import HTMLTooltip from "./HTMLTooltip.vue";
import {
    files as _files
} from "../../standardAttachables";
import { buildLimitations } from "./utils";
import {
    get
} from "lodash";
import store from "../../../store";

// const route = useRoute();

let files = {
    ..._files
}
let files2 = {
    ..._files
}

let testersFiles = {
    ..._files
}

files.actions = [{
    "label": "Upload File",
    "action": uploadFile
}];

files.rowActions = [{
    "label": "Download",
    "action": downloadFile
}, {
    "label": "Delete File",
    "class": "text-red",
    "action": deleteFile
}];

testersFiles.actions = [{
    "label": "Upload File",
    "action": addFileToTester
}];

testersFiles.rowActions = [{
    "label": "Download",
    "action": downloadTesterFile
}, {
    "label": "Delete File",
    "class": "text-red",
    "action": deleteFile
}];

files2.actions = [];

files2.rowActions = [{
    "label": "Download",
    "action": downloadFile2
}];

const show = ({ store, pageItem }) => {
    return (store?.state?.user?.Security?.Matrices?.Reviewer === true) || pageItem?.AccountID === store.getters.userID;
};

files.show = show;
files2.show = show;

export const panels = {
    "label": "Panels",
    "key": "Panels",
    "local": true,
    "paginationPageSize": 50,
    "headers": [{
        "headerName": "Panel",
        valueFormatter(params) {
            return store?.state["Matrices/SignatoryMatrix/Panels"][params.data.ID].NamePretty;
        }
    }, {
        "headerName": "Checker / Approver / CVE",
        "field": "Answer",
        "type": "cve"
    }, {
        "headerName": "Limitations",
        "field": "Limitations",
        "flex": 1,
        valueFormatter(params) {
            if (params.data.Answer === "None" || params.data.Answer === false) return "N/A";
            let limitations = Object.values(params.data.Limitations || []);
            return `${limitations.length} Limitation${limitations.length === 1 ? "" : "s"}`;
        },
        cellStyle(params) {
            return !params.data.Answer || (params.data.Answer === "None") ? {
                "color": "#ccc"
            } : null
        },
        tooltipComponent: HTMLTooltip,
        tooltipShowDelay: 0,
        tooltipValueGetter(params) {

            return params.data.Limitations ? `<p>${Object.values(params.data.Limitations || {}).map(i => i.Limitation).join("<br>")}</p> ` : "No Limitations";
        }
    }],
    // "actions": [{
    //     "label": "Update Panels",
    //     "action": updatePanels,
    //     show({ store }) { return [store?.state?.user?.Security?.Matrices?.Reviewer === true, "You cannot update panels"] }
    // }],
    "rowActions": [{
        "label": "Update Panel",
        "action": updatePanel,

        show({ store }) {
            const viewingNew = store.state.sigMatrixViewingNew;
            const isReviewer = store?.state?.user?.Security?.Matrices?.Reviewer === true;
            const errorMessage = !isReviewer ? "You must be a reviewer to update panels." : "To change panels, you must be viewing the latest changes of the matrix.";
            return [viewingNew && isReviewer, errorMessage];
        }
    }, {
        "label": "Add Limitation",
        "action": addPanelLimitations,
        "break": true,
        show({ store }) {
            const viewingNew = store.state.sigMatrixViewingNew;
            const isReviewer = store?.state?.user?.Security?.Matrices?.Reviewer === true;
            const errorMessage = !isReviewer ? "You must be a reviewer to update panels." : "To change panels, you must be viewing the latest changes of the matrix.";
            return [viewingNew && isReviewer, errorMessage];
        }
    }, {
        "label": "Update Limitations",
        "action": updatePanelLimitations,
        show({ store }) {
            const viewingNew = store.state.sigMatrixViewingNew;
            const isReviewer = store?.state?.user?.Security?.Matrices?.Reviewer === true;
            const errorMessage = !isReviewer ? "You must be a reviewer to update panels." : "To change panels, you must be viewing the latest changes of the matrix.";
            return [viewingNew && isReviewer, errorMessage];
        }
    }]
};

export const limitations = {
    "label": "Limitations",
    "field": true,
    "fields": [{
        "label": "All limitations",
        "class": "md:col-span-3",
        "grow": true,
        compute({ item }) {
            return buildLimitations(item, store)
        }
    }],
    "actions": [{
        "label": "Update Additional Limitations",
        "action": updateAdditionalLimitations,
        show({ store }) { return [store?.state?.user?.Security?.Matrices?.Reviewer === true, "You cannot update additional Limitations"] }
    }],
    computedActions({ form, store, pageItem, router }) {

        let panels = Object.values(pageItem?.Panels || {}).filter(i => {
            return !!i.Limitations;
        }).map((i, index) => {
            return {
                "break": index === 0,
                "label": `Update - ${store.state["Matrices/SignatoryMatrix/Panels"][i.ID].NamePretty}`,
                "action": updatePanelLimitations2({ form, rowItem: i, pageItem }),
                "show": () => [router.currentRoute.value.params.showLatest === "true", "To change limitations, you must be viewing the latest changes of the matrix."]
            };
        });
        return panels;
    }
};

export const notes = {
    "label": "Notes",
    "key": "Notes",
    "local": true,
    "headers": [{
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Note",
        "field": "Note",
        "type": "html"
    }, {
        "headerName": "Date",
        "field": "Date",
        "type": "date"
    }],
    "actions": [{
        "label": "Add Note",
        "action": addNote
    }],
    "rowActions": [{
        "label": "View Note",
        "action": viewNote
    }, {
        "label": "Update Note",
        "action": updateNote
    }],
    "show": show
};

export { files, files2, testersFiles };

export const formfour = {
    "label": "Form Four Data",
    "key": "Form Four Data",
    "field": true,
    "fields": [{
        "label": "CV",
        "key": "FormFourFiles.CV",
        "actionButton": true,
        "actionText": "Download",
        async action({ pageItem, db }) {
            const url = await db.download(`people/${pageItem?.ID}/${pageItem?.FormFourFiles.CV}`);
            window.open(url, "_blank");
        },
        actionDisabled({ pageItem }) {
            return !(!!pageItem?.FormFourFiles?.CV);
        }
    }, {
        "label": "EASA",
        "key": "FormFourFiles.EASA",
        "actionButton": true,
        "actionText": "Download",
        async action({ pageItem, db }) {
            const url = await db.download(`people/${pageItem?.ID}/${pageItem?.FormFourFiles.EASA}`);
            window.open(url, "_blank");
        },
        actionDisabled({ pageItem }) {
            return !(!!pageItem?.FormFourFiles?.EASA);
        }
    }, {
        "label": "Sample Signature",
        "key": "FormFourFiles.SampleSignature",
        "actionButton": true,
        "actionText": "Download",
        async action({ pageItem, db }) {
            const url = await db.download(`people/${pageItem?.ID}/${pageItem?.FormFourFiles.SampleSignature}`);
            window.open(url, "_blank");
        },
        actionDisabled({ pageItem }) {
            return !(!!pageItem?.FormFourFiles?.SampleSignature);
        }
    }],
    "actions": [{
        "label": "Add CV",
        "action": addFormFourFile("CV")
    }, {
        "label": "Link a CV from an existing file",
        "action": linkFormFourFile("CV")
    }, {
        "label": "Add an EASA Post Holder document",
        "action": addFormFourFile("EASA"),
        "break": true
    }, {
        "label": "Link an EASA Post Holder document from an existing file",
        "action": linkFormFourFile("EASA")
    }, {
        "label": "Add a Sample Signature",
        "action": addFormFourFile("SampleSignature"),
        "break": true
    }, {
        "label": "Link a Sample Signature from an existing file",
        "action": linkFormFourFile("SampleSignature")
    }],
    show({ pageItem, store }) {
        return Object.keys(pageItem?.ApprovedFunctions || {}).some(key => {
            let af = store.state["Matrices/SignatoryMatrix/ApprovedFunctions"][key];
            return af?.FormFourFunction || false;
        }) && show({ pageItem, store })
    }
};

const cellClassRules = {
    "text-orange font-bold": params => {
        return params.data?.hasAnswered !== true;
    }
};

export const questions = {
    "label": "Application Questions",
    "key": "InterviewQuestions",
    // "local": true,
    "paginationPageSize": 50,
    "headers": [{
        "headerName": "Question",
        "field": "Question",
        "flex": 1,
        cellClassRules
    }, {
        "headerName": "Question Answered",
        "field": "hasAnswered",
        "type": "bool",
    }, {
        "headerName": "Points Awarded",
        "field": "Points",
        "type": "number",
        cellClassRules
    }],
    "fields": [{
        "label": "Role Point Threshold",
        compute({ pageItem, store }) {
            return get(store.state, `Matrices/SignatoryMatrix/ApprovedFunctions.${pageItem?.Role}.Threshold`, 0);
        },
        show({ pageItem }) {
            return !!pageItem?.Role
        }
    }, {
        "label": "Total Points Awarded",
        compute({ pageItem }) {
            return Object.values(pageItem?.InterviewQuestions || {}).reduce((acc, q) => {
                return acc + parseFloat(q.Points || 0);
            }, 0);
        }
    }],
    "actions": [],
    "rowActions": [{
        "label": "Review Answer",
        "action": reviewAnswer("Matrices/SignatoryMatrix"),
        show({ rowItem }) {
            return [rowItem.hasAnswered, "This question has not been answered"]
        }
    }, {
        "label": "Answer Question",
        "action": answerQuestion("Matrices/SignatoryMatrix"),
        show({ store, pageItem }) {
            return [pageItem?.AccountID === store.getters.userID, "Only the assigned User can answer this."]
        }
    }],
    show({ pageItem, store }) {
        return show({ pageItem, store }) && pageItem?.PointsExempt !== true;
    },
    computedData({ store, pageItem }) {
        let questions = store.state["Matrices/SignatoryMatrix/InterviewQuestions"];
        let approvedFunctions = [...(Object.keys(pageItem?.ApprovedFunctions || {}))];

        return Object.values(questions).filter(question => {
            return approvedFunctions.some(af => {
                return Object.keys(question.Roles).includes(af);
            });
        }).map(i => {

            let answer = get(pageItem, `InterviewQuestions.${i.ID}`, {});

            return {
                "Question": i.Question,
                "ID": i.ID,
                "q": i,
                "a": answer,
                ...answer,
                "hasAnswered": Object.keys(answer).length > 0
            }
        });
    },
    warn({ items }) {
        // Check if all questions have been reviewed
        let hasUnreviewedQuestions = [!items.every(i => !!i.Points), "Not all questions have been reviewed."];

        // Check if all questions have been answered
        let hasUnansweredQuestions = [!items.every(i => i.hasAnswered === true), "Not all questions have been answered."];

        // Return the combined warning message if either condition is true
        return [hasUnreviewedQuestions[0] === true || hasUnansweredQuestions[0] === true,
        `
      ${hasUnansweredQuestions[0] === true ? `${hasUnansweredQuestions[1]}\n` : ''}
    `
        ];
    }
};

export const questions2 = {
    ...questions,
    computedData({ store, pageItem }) {
        let questions = store.state["Matrices/SignatoryMatrix/InterviewQuestions"];

        return Object.values(questions).filter(question => {

            return Object.keys(question.Roles).includes(pageItem?.Role);

        }).map(i => {

            let answer = get(pageItem, `InterviewQuestions.${i.ID}`, {});
            return {
                "Question": i.Question,
                "ID": i.ID,
                "q": i,
                "a": answer,
                ...answer,
                "hasAnswered": Object.keys(answer).length > 0
            }
        });
    },
    "rowActions": [{
        "label": "Review Answer",
        "action": reviewAnswer(`OpenForms`),
        show({ rowItem }) {
            return [rowItem.hasAnswered, "This question has not been answered"]
        }
    }, {
        "label": "Answer Question",
        "action": answerQuestion("OpenForms"),
        show({ store, pageItem }) {
            return [pageItem?.AccountID === store.getters.userID, "Only the assigned User can answer this."]
        }
    }]
};


export const records = {
    "label": "Records",
    "key": "TrainingRecord.Records",
    "local": true,
    "headers": [{
        "headerName": "Date Start",
        "field": "Date.Start",
        "type": "date"
    }, {
        "headerName": "Date Complete",
        "field": "Date.Complete",
        "type": "date"
    }, {
        "headerName": "Training / Achievement / Experience",
        "field": "Training"
    }, {
        "headerName": "Outcomes / Comments",
        "field": "Outcomes"
    }, {
        "headerName": "Type",
        "field": "Type"
    }, {
        "headerName": "Approved By",
        "field": "ApprovedBy"
    }],
    "actions": [{
        "label": "Add Record",
        "action": addRecord
    }],
    "rowActions": [{
        "label": "Update Record",
        "action": updateRecord
    }, {
        "label": "Download Certificate",
        async action({ db, rowItem, router }) {
            let url = await db.download(`StaffTrainingRecords/${router.currentRoute.params.id}/${rowItem.ID}/${rowItem.Certificate}`);
            window.open(url, "__blank");
        },
        show({ rowItem }) {
            return [rowItem.Certificate, "Certificate not attached"];
        }
    }, {
        "label": "Remove Record",
        "action": removeRecord
    }],
    show({ store, pageItem }) {
        return (store?.state?.user?.Security?.General?.UserAdmin === true) || pageItem?.AccountID === store.getters.userID;
    
    }
};

export const designChanges = {
    "label": "Design Change's Responsible For",
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Title",
        "field": "Title"
    }, {
        "headerName": "Mod Type",
        "field": "Type"
    }],
    "rowActions": [{
        "label": "View Design Change",
        action({ router, rowItem }) {
            router.push(`/Database/DesignChanges/${rowItem.ID}`)
        }
    }],
    computedData({ store, pageItem }) {
        if (!pageItem) return [];
        return Object.values(store.state["Database/Refs/DesignChanges"] || {}).filter(i => {
            return i.ResponsibleEngineer === `${pageItem.FirstName} ${pageItem.LastName}`;
        });
    }
};


export const internalTraining = {

};

export const additionalRoles = {
    "label": "Additional Roles",
    "field": true,
    "fields": [{
        "label": "Roles",
        "class": "md:col-span-3",
        "grow": true,
        compute( { item }) {
            return item?.Roles || "None";
        }
    }],
    "actions": [{
        "label": "Update Additional Roles",
        "action": updateAdditionalRoles,
        show({ store }) { return [store?.state?.user?.Security?.Matrices?.Reviewer === true, "You cannot update additional roles"] }
    }]
}
