import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faPhone,    faArrowLeft,        
    faEye,
    faBars,
    faHome,
    faDatabase,
    faCheck,
    faTimes,
    faPoundSign,
    faCalendar,
    faStopwatch,
    faUser,
    faUsers,
    faSearch,
    faSyncAlt,
    faPlaneDeparture,
    faUsersCog,
    faSignOutAlt,
    faEllipsisV,
    faFileContract,
    faLock,
    faExclamationCircle,
    faPlane,    faBook,
    faCheckCircle,    faTrash,
    faDownload,
    faInfoCircle,
    faTable,
    faArrowsRotate,
    faArrowsUpToLine,
    faChartBar
} from "@fortawesome/free-solid-svg-icons";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

library.add(
    faPhone,    faArrowLeft,        
    faEye,
    faBars,
    faHome,
    faDatabase,
    faCheck,
    faTimes,
    faChartBar,
    faTable,
    faPoundSign,
    faCalendar,
    faStopwatch,
    faUser,
    faSearch,
    faSyncAlt,
    faUsers,
    faPlaneDeparture,
    faUsersCog,
    faSignOutAlt,
    faEllipsisV,
    faFileContract,
    faLock,
    faExclamationCircle,
    faArrowsUpToLine,
    faPlane,    faBook,
    faCheckCircle,    faTrash,
    faDownload,
    faArrowsRotate,
    faInfoCircle
);

export default FontAwesomeIcon;
