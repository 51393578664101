import {
    addDQN,
    cancelDQN,
    editDQN,
    linkDQN,
    signoffDQN,
    unlinkDQN
} from "./actions";

import store from "../../../store";

function warning(key) {
    let item = store.state[key];
    if (!item || item.Cancelled === true) return false;

    let warningMessage = "";
    if (item?.Signedoff === false) {
        warningMessage = warningMessage += `${item.Number} is not signedoff.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
};

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
};

const actions = [{
    "label": "Add DQN",
    "action": addDQN,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "DQNs cannot be added here, they must be linked."]
    }
}, {
    "label": "Link DQN",
    "action": linkDQN
}];

const headers = [{
    "headerName": "Number",
    "field": "Number",
    cellClassRules
}, {
    "headerName": "Comment",
    "field": "Comment",
    cellClassRules
}, {
    "headerName": "Signed Off",
    "field": "Signedoff",
    "type": "bool",
    cellClassRules
}];

const rowActions = [{
    "label": "View DQN",
    action({ router, rowItem }) {
        router.push(`/Database/DQNs/${rowItem.ID}`)
    }
}, {
    "label": "Update DQN",
    "break": true,
    "action": editDQN,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Repairs"].includes(meta?.ParentType), "Can only be edited whilst in the parent"]
    }
}, {
    "label": "Signoff DQN",
    "action": signoffDQN
    }, {
        "label": "Unlink DQN",
        "break": true,
        "action": unlinkDQN
    }, {
    "label": "Cancel DQN",
    "break": true,
    "action": cancelDQN,
    "class": "text-red"
}];

export default {
    "label": "DQNs",
    "key": "DQNs",
    "location": "Database/DQNs/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        
        let warningMessage = items.map(warning).filter(i => i !== false).join("<br>");
        return [warningMessage !== "", warningMessage];
    }
};
