import { chain } from "lodash";

export const next = (r) => {
    if (typeof r === "string") {
        // Check if the input string is an empty string
        if (!r) {
            return "";
        }

        // Check if the input string starts with 'P' followed by a number
        if (/^P\d+$/.test(r)) {
            const num = parseInt(r.substring(1), 10);
            return `P${num + 1}`;
        }


        // Check if the input string is a number
        const num = parseInt(r, 10);
        if (!isNaN(num)) {
            return num + 1;
        }

        // Check if the input string is an alphabetical character
        if (/^[a-zA-Z]$/.test(r)) {
            // Check if the input string is at the end of the alphabet
            if (r === "z") {
                return "aa";
            }
            if (r === "Z") {
                return "AA";
            }

            // Increment the alphabetical character
            return String.fromCharCode(r.charCodeAt(0) + 1);
        }

        // Return an empty string for any other input string
        return "";
    }
    else if (typeof r === "number") {
        return r + 1;
    }

    // Return undefined for any other data type
    return undefined;
};

export const pad = (num = "", size = 4) => {
    // Convert the input to a string if it's not already a string
    num = num.toString();

    // Calculate the number of characters to pad
    const padding = size - num.length;

    // Return the padded string
    return padding > 0 ? "0".repeat(padding) + num : num;
};

// This function flattens an object into a single-level object, where the keys are separated by a specified separator.
export const flattenObject = (obj, keySeparator = '/') => {
    // The resulting flattened object
    const propertyMap = {};

    // A stack to keep track of the objects and their prefixes
    const stack = [{ obj, prefix: '' }];

    // Use an iterative approach to traverse the object
    while (stack.length) {
        // Pop the next object and prefix from the stack
        const { obj, prefix } = stack.pop();

        // Iterate over the entries in the current object
        for (const [key, value] of Object.entries(obj)) {
            // Construct the property name using the prefix and key
            const property = prefix ? `${prefix}${keySeparator}${key}` : key;

            // If the value is an object, push it onto the stack with the updated prefix
            if (value && typeof value === 'object') {
                stack.push({ obj: value, prefix: property });
            } else {
                // Otherwise, add the property to the propertyMap
                propertyMap[property] = value;
            }
        }
    }

    // Return the propertyMap
    return propertyMap;
};


export const standardDateLocal = {
    "year": "numeric",
    "month": "long",
    "day": "numeric",
}

export const addToRecents = ({ currentRoute, pageItem, store, db }) => {
    if (!pageItem || !pageItem.Number) return;
    const newRecents = [
        ...(store.state.user.Recents || []),
        {
            link: currentRoute.path,
            name: pageItem.Number,
            time: new Date().toISOString(),
            type: currentRoute.meta.name,
        },
    ];
    const recents = chain(newRecents).sortBy("time").reverse().uniqBy("name").value();
    if (recents.length > 10) recents.pop();
    db.set(`Userdata/${store.state.user.ID}/Recents/`, recents);
};

export const makeDropdown = (store, array = [], labelKey, idKey) => {
    let x = array
        .map(i => store.state[i])
        .filter(i => !!i)
        .map(i => ({ "label": i[labelKey], "id": i[idKey], "meta": i }));
    return x;
};

export const makeAttached = (array, value = true) => {
    if (Array.isArray(array) && array.length > 0) return Object.fromEntries(array.map(i => ([i, value])));
    else if (typeof array === "string") return { [array]: value };
    else return [];
};

export const keepArray = (array) => {
    if (Array.isArray(array)) return array
    else return [array];
};

export const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);
