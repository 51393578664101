import { appConfig, makeAttached, pad } from "../../../utils";

import {
    createBasicGreenCard
} from "../JobCards/actions";

export const addOccurrence = ({ form, item, store }) => {
    form.setConfig({
        "title": "Add Occurrence",
        "type": "push",
        "location": `Database/Occurrences`,
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}OCC-${pad(store.state.counts.occ.count)}`
            }
        }, {
            "label": "Title",
            "name": "Title",
            "type": "text",
        }, {
            "label": "Aircraft Code",
            "name": "Aircraft",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "ID",
            "dataLocation": "Database/AircraftCodes"
        }],
        preSubmitHook({ data }) {
            return {
                "Aircraft": makeAttached(data.Aircraft),
                "Number": `${appConfig.docPrefix}OCC-${pad(store.state.counts.occ.count)}`,
                "Reportable": false,
                "MOR": false,
                "Date": {
                    "Created": (new Date()).toISOString()
                }
            }
        },
        async postSubmitHook({ db, newData }) {
            let greenCard = createBasicGreenCard({ store, parent: newData });
            await db.set(`Database/Occurrences/${newData.ID}/JobCards`, greenCard.ID);
            await db.set(`Database/JobCards/${greenCard.ID}`, greenCard);
            await db.utils.upCount("counts/occ/count");
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editOccurrence = ({ form, pageItem }) => {
    form.setValues({...pageItem, "Aircraft": Object.keys(pageItem.Aircraft || {})});
    form.setConfig({
        "title": "Add Occurence",
        "type": "update",
        "location": `Database/Occurrences/${pageItem.ID}`,
        "fields": [{
            "label": "Title",
            "name": "Title",
            "type": "text",
        }, {
            "label": "Aircraft Code",
            "name": "Aircraft",
            "sortKey": "meta.Number",
            "type": "dropdown",
            "labelKey": "NameFull",
            "idKey": "ID",
            "dataLocation": "Database/AircraftCodes"
        }],
        preSubmitHook({ data }) {
            return {
                "Aircraft": makeAttached(data.Aircraft)
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

