import { completeEadt, eadtFields, editEadtData } from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue";
import ItemView from "../../../components/ItemView/ItemView.vue";

export default [{
    "path": "/Database/EADTs",
    "component": { ...ItemListView },
    "name": "EADTs",
    "meta": {
        "app": "Database",
        "name": "EADTs",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "EADTs",
        "dataPath": "Database/Refs/Documents",
        "selectionRoute": "Database/EADTs/{ID}",
        filter(item) {
            return item.Number.includes(".EADT-");
        }
    }
}, {
    "path": "/Database/EADTs/:id",
    "component": ItemView,
    "name": "EADT",
    "meta": {
        "app": "Database",
        "name": "EADT",
        "dataPathOverride": "Database/Documents/:id",
        title(item) {
            return item.Name;
        },
        "menuActions": [{
            "label": "Update EADT Data",
            "action": editEadtData,
            show({ pageItem }) {
                return [pageItem.Completed !== true, "EADT is completed."];
            }
        }, {
            "label": "Complete EADT",
            "action": completeEadt,
            show({ pageItem }) {
                return [pageItem.Completed !== true, "EADT is completed."];
            }
        }],
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Parent",
            "key": "Parent",
            "link": "Database/:ParentType/:ParentID"
        }, {
            "label": "Is Completed",
            "key": "Completed",
            "type": "bool"
        },{
            "label": "Date Completed",
            "key": "Date.Completed",
            "type": "date"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-3"
        }, ...eadtFields],
        "formMetadata": {
            "ParentType": "Documents"
        }
    }
}];
