import Audits from "./Audits/routes.js";
import Claims from "./Claims/routes.js";
import Database from "./Database/routes.js";
import FlightTestCrew from "./FlightTestCrew/routes.js";
import Home from "./Home/routes.js";
import Leave from "./Leave/routes.js";
import Matrices from "./Matrices/routes.js";
import OpenForms from "./OpenForms/routes.js";

export default [
    ...Home,
    ...Database,
    ...Audits,
    ...Leave,
    ...Matrices,
    ...FlightTestCrew,
    ...OpenForms,
    ...Claims
];
