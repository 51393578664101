<template>
  <div
    :class="`flex flex-row items-center ${!show ? 'pointer-events-none' : ''}`"
    @click="$emit('change')"
    v-show="hidden !== true"
  >
    <p :class="labelClass" v-html="label"></p>
    <font-awesome-icon
      v-show="show === false"
      icon="lock"
      class="text-gray mr-4"
    ></font-awesome-icon>
    <font-awesome-icon
      v-show="show === true && warn === true"
      icon="exclamation-circle"
      class="text-orange mr-4"
      v-tooltip="{ content: warnText, html: true }"
    ></font-awesome-icon>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  label: String,
  warn: Boolean,
  show: Boolean,
  selected: Boolean,
  warnText: String,
  hidden: Boolean
});

const labelClass = computed(() => {
  const selected = props.selected ? "text-primary font-medium" : "";
  const show = props.show === false ? "pointer-events-none text-gray-400" : "";
  return `cursor-pointer flex-grow hover:text-primary hover:font-medium transition py-1 pl-3 text-sm ${selected} ${show}`;
});
</script>
4
