import {
    appConfig,
    makeAttached,
    makeDropdown
} from "../../../utils.js";

import store from "../../../store.js";

export const addSADD = ({ form, pageItem, attachedItems, meta, store }) => {

    const mdl = attachedItems.Documents.items.map(i => store.state[i]).find(doc => {
        return doc?.Type === (meta.ParentType === "Repairs" ? "REP" : "MDL");
    });
    let revisions = [];
    if (!!mdl) {
        revisions = Object.entries(mdl?.Revisions || {}).map(([key, value]) => {
            return {
                label: value.Revision,
                id: value.Revision
            };
        });

        form.setValues({
            "Number": `${appConfig.docPrefix}${(meta.ParentType === "Repairs" ? "SADD_" : "")}${pageItem.Number}_${Object.keys(pageItem.SADDs || {}).length + 1}`,
            "Documents": [mdl?.ID]
        })
    } else {
        form.setValues({
            "Number": `${appConfig.docPrefix}${(meta.ParentType === "Repairs" ? "SADD_" : "")}${pageItem.Number}_${Object.keys(pageItem.SADDs || {}).length + 1}`,
        });
    }

    form.setConfig({
        "title": "Add SADD",
        "type": "push",
        "location": "Database/SADDs",
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "SADD Type",
            "name": "Type",
            "type": "dropdown",
            "required": true,
            "dropdownData": ["Installation", "Manufacture", "Testing"].map(i => ({ label: i, id: i }))
        }, {
            "label": "POA/Arrangement",
            "name": "To",
            "type": "text",
            show({ liveInputData }) {
                return liveInputData.Type === "Manufacture";
            }
        }, {
            "label": "POA/Arrangement",
            "name": "To",
            "type": "value",
            show({ liveInputData }) {
                return liveInputData.Type !== "Manufacture";
            },
            value() {
                return "N/A";
            }
        }, {
            "label": "Date Issued",
            "name": "Date[Issued]",
            "type": "date"
        }, {
            "divClass": "col-span-3",
            "break": true
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents?.items, "Number", "ID"),
            "divClass": "col-span-2"
        }, {
            "label": "Select MDL Revision",
            "name": "MDL[Revision]",
            "type": "dropdown",
            "required": false,
            "dropdownData": revisions
        }, {
            "divClass": "col-span-3",
            "break": true
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings?.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities?.items, "Number", "ID")
        }, {
            "label": "Select DQN's",
            "name": "DQNs",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.DQNs?.items, "Number", "ID")
        }, {
            "label": "Select Concession's",
            "name": "Concessions",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Concessions?.items, "Number", "ID")
        }, {
            "label": "Select Service Bulletin's",
            "name": "ServiceBulletins",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems?.ServiceBulletins?.items, "Number", "ID")
        }, {
            "label": "Select Miscellaneous / 3rd Party Items",
            "name": "Miscellaneous",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Miscellaneous?.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A",
                    "Issued": data.Date["Issued"] || "N/A"
                },
                "Cancelled": false,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "ServiceBulletins": makeAttached(data.ServiceBulletins),
                "Effectivities": makeAttached(data.Effectivities),
                "DQNs": makeAttached(data.DQNs),
                "Concessions": makeAttached(data.Concessions),
                "Miscellaneous": makeAttached(data.Miscellaneous),
                "To": data.To || "N/A",
                "Number": data.Type === "Manufacture" ? data.Number + "M" : data.Type === "Testing" ? data.Number + "T" : data.Number
            }
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editSADD = async ({ form, item, attachedItems }) => {
    const mdl = attachedItems.Documents.items.map(key => store.state[key]).find(doc => {
        return doc.Type === "MDL";
    });

    let revisions = Object.entries(mdl.Revisions).map(([key, value]) => {
        return {
            label: value.Revision,
            id: key
        };
    });

    form.setValues({
        ...item,
        "Date[Issued]": item.Date.Issued,
        "MDL[Revision]": item.MDL?.Revision,
        "Documents": Object.keys(item.Documents || {}),
        "Drawings": Object.keys(item.Drawings || {}),
        "Effectivities": Object.keys(item.Effectivities || {}),
        "ServiceBulletins": Object.keys(item.ServiceBulletins || {}),
        "DQNs": Object.keys(item.DQNs || {}),
        "Concessions": Object.keys(item.Concessions || {})
    });
    form.setConfig({
        "title": "Update SADD",
        "type": "update",
        "location": `Database/SADDs/${item.ID}`,
        "set": true,
        "fields": [{
            "label": "Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "SADD Type",
            "name": "Type",
            "type": "dropdown",
            "required": true,
            "dropdownData": ["Installation", "Manufacture", "Testing"].map(i => ({ label: i, id: i }))
        }, {
            "label": "POA/Arrangement",
            "name": "To",
            "type": "text"
        }, {
            "label": "Date Issued",
            "name": "Date[Issued]",
            "type": "date"
        }, { divClass: "col-span-3", break: true }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Documents?.items, "Number", "ID"),
            "divClass": "col-span-2"
        }, {
            "label": "Select MDL Revision",
            "name": "MDL[Revision]",
            "type": "dropdown",
            "required": false,
            "dropdownData": revisions
        }, { divClass: "col-span-3", break: true }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings?.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities?.items, "Number", "ID")
        }, {
            "label": "Select DQN's",
            "name": "DQNs",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.DQNs?.items, "Number", "ID")
        }, {
            "label": "Select Concession's",
            "name": "Concessions",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Concessions?.items, "Number", "ID")
        }, {
            "label": "Select Service Bulletin's",
            "name": "ServiceBulletins",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.ServiceBulletins?.items, "Number", "ID")
        }, {
            "label": "Select Miscellaneous / 3rd Party Items",
            "name": "Miscellaneous",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Miscellaneous?.items, "Number", "ID")
        }],
        preSubmitHook({ data }) {
            return {
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "ServiceBulletins": makeAttached(data.ServiceBulletins),
                "Effectivities": makeAttached(data.Effectivities),
                "DQNs": makeAttached(data.DQNs),
                "Concessions": makeAttached(data.Concessions),
                "Miscellaneous": makeAttached(data.Miscellaneous)
            }
        }
    });
    await form.getDropdownData();
    form.toggle();
};

export const cancelSADD = ({ form, item }) => {
    form.setConfig({
        "title": "Cancel SADD",
        "description": `Are you sure you want to cancel SADD ${item.Number}?`,
        "type": "update",
        "location": `Database/SADDs/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            }
        }
    });
    form.toggle();
};

export const linkSADD = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Link SADD",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}`,
        "fields": [{
            "label": "SADD",
            "name": "SADD",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/SADDs",
            "search": true
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "SADD": null,
                [`SADDs/${liveInputData.SADD}`]: true
            }
        },
        postSubmitHook({ db, liveInputData }) {
            db.update(`Database/SADDs/${liveInputData.SADD}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();

    form.toggle();
};
